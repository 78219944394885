import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datePipe'
})

export class DatePipe implements PipeTransform {
    transform(value: string): string {
        const toDate = new Date(value);
        const hours = new Date(toDate).getHours();
        const minutes = new Date(toDate).getMinutes();
        
        return `${hours}h ${minutes}min`;
    }
}