import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hoursPipe'
})

export class HoursPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return HOURS[0];
        } else {
            return HOURS[value];
        }
    }
}

export const HOURS: string[] = [
    'Not set',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
    'twenty one',
    'twenty two',
    'twenty three',
    'twenty four'
]