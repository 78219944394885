<div class="slider-container" [class.slim]="slim">
    <ng-container *ngIf="bannerImages.length > 1; else oneImage">
        <div
                class="slider-container__carousel"
                *ngIf="selectedBanner"
        >
            <ng-template *ngTemplateOutlet="imageTemplate; context: {$implicit: selectedBanner}"></ng-template>
            <div class="actions">
                <button class="btn btn-icon btn-previous" (click)="previousSlide()">
                    <i class="u_arrow-left"></i>
                </button>
                <button class="btn btn-icon btn-next" (click)="nextSlide()">
                    <i class="u_arrow-right"></i>
                </button>
            </div>
            <div class="dots">
                <div class="dot"
                     (click)="changeIndex(i)"
                     *ngFor="let image of bannerImages; let i = index"
                     [class.selected]="i === selectedIndex">
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #oneImage>
        <ng-template *ngTemplateOutlet="imageTemplate; context: {$implicit: selectedBanner}"></ng-template>
    </ng-template>
</div>

<ng-template #imageTemplate let-image>
    <ng-container *ngIf="image">
        <ng-container *ngIf="!image.__component || image.__component === BannerBlocksTypes.Simple">
            <div class="image-container" [class.loading]="isLoading" (click)="onActionClick(image.Action, image)">
                <img
                        [alt]="$simpleImage(image).Alt"
                        [src]="getCurrentImageSource(image)"
                        (load)="onImageLoad()"
                        [class.inactive]="!image.Action"
                        width="100%"
                        height="100%"
                />
            </div>
        </ng-container>
        <ng-container *ngIf="image.__component === BannerBlocksTypes.Advanced">
            <div
                    *ngLet="$advancedImage(image) as advancedImage"
                    [class.loading]="isLoading"
                    class="advanced-image-container"
            >
                <div class="info-content {{getContentAlignmentClass(advancedImage)}}">
                    <div
                            *ngIf="advancedImage.Content"
                            [innerHTML]="advancedImage.Content | safe: 'html'"
                            class="info-content__html"
                    >
                    </div>
                    <div class="info-content__actions">
                        <button
                                class="btn {{getActionButtonClass(action)}}"
                                *ngFor="let action of advancedImage.Actions"
                                (click)="onActionClick(action.Type, image)"
                        >
                            {{ action.Text }}
                        </button>
                    </div>
                </div>
                <img
                        [style.aspect-ratio]="getCurrentAspectRatio(advancedImage)"
                        [src]="getCurrentImageSource(advancedImage)"
                        (load)="onImageLoad()"
                        alt="banner-image"
                />
            </div>
        </ng-container>
    </ng-container>
</ng-template>
