<div class="details-container" *ngIf="level">
    <div class="details-item criteria" *ngIf="level.Criteria">
        <div class="details-item__title">Completion criteria</div>
        <div [innerHTML]="this.level?.Criteria | safe: 'html'"></div>
    </div>

    <div class="details-item reward" *ngIf="level.Reward">
        <div class="details-item__title">Completion reward</div>
        <div [innerHTML]="this.level?.Reward | safe: 'html'"></div>
    </div>

    <div class="details-item perk" *ngIf="level.Perks">
        <div class="details-item__title">Perks</div>
        <div [innerHTML]="this.level?.Perks | safe: 'html'"></div>
    </div>

<!--    <div class="details-item"><a href="/pages/terms">Terms & Conditions</a> apply.</div>-->
</div>
