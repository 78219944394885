import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'datetimePipe' })
export class DatetimePipe implements PipeTransform {
    public transform(
        value: string | number | Date | null | undefined,
        template = 'MM/DD/YYYY hh:mm A'
    ): string {
        if (!value) return "";

        // utc true because devextreme adds local on parsing...
        // return dayjs(value).utc(true).local().format(template);

        // return dayjs(value).utc(true).local().format(template);

        return dayjs(value).format(template);
    }
}
