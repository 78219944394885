import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {getFormData} from "../utils";
import {BankAccount, BankDetails, LinkBankAccountRequest, LinkBankAccountResponse} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class BankDetailsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getBankDetails(): Observable<BankDetails> {
        const url = `${environment.ENDPOINTS.API.URL}/bank-details`;

        return this.http.get<BankDetails>(url);
    }

    public saveBankDetails(details: BankDetails): Observable<void> {
        const url = `${environment.ENDPOINTS.API.URL}/bank-details`;
        const bankDetails = getFormData(details);

        return this.http.post<void>(url, bankDetails);
    }

    public updateSSN(ssn: string, playerId: string): Observable<any> {
        const url = `${environment.ENDPOINTS.API.URL}/players/${playerId}/ssn`;

        return this.http.post<void>(url, {ssn});
    }

    public getBankAccounts(): Observable<BankAccount[]> {
        const url = `${environment.ENDPOINTS.API.URL}/bank-details/accounts`;

        return this.http.get<BankAccount[]>(url);
    }

    public linkBankAccounts(data: LinkBankAccountRequest): Observable<LinkBankAccountResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/bank-details/accounts`

        return this.http.post<LinkBankAccountResponse>(url, data);
    }
}
