import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {LobbyCollection} from "../interfaces/lobby";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class CollectionsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getCollection(collectionSlug: string): Observable<LobbyCollection> {
        const url = `${environment.ENDPOINTS.CMS.URL}/collections/${collectionSlug}`;

        return this.http.get<LobbyCollection>(url);
    }
}
