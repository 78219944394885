<div class="bank-details-content">
    <div class="bank-details-logo">
        <img class="" src="assets/images/success.png" alt="bank-details">
    </div>
    <div class="bank-details-content__description">
        Bank details successfully added
    </div>
    <button class="btn btn-large btn-block" (click)="close()">
        Ok
    </button>
</div>