<ng-container *ngIf="showButton">
    <button class="btn btn-block btn-extra-large btn-white payment-button" (click)="initPayment()" [disabled]="inProgress">
        <span class="icons-container">
            <span class="icons">
                <img src="../../../../../assets/images/cards/BankOfAmerica.svg" alt="BankOfAmerica">
                <img src="../../../../../assets/images/cards/Chase.svg" alt="Chase">
                <img src="../../../../../assets/images/cards/Citi.svg" alt="Citi">
            </span>
            <span class="hint">and MORE</span>
        </span>
        <span class="text-container">
            <span class="accent">Instant</span>
            <span>Bank Transfer</span>
        </span>
    </button>
</ng-container>
<div class="modal-container" *ngIf="hasIframe">
    <div class="modal-backdrop"></div>
    <div class="modal">
        <div class="modal__header">
            <h1>Checkout</h1>
            <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
        </div>
        <div class="modal__content" *ngIf="iframeUrl">
            <iframe [src]="iframeUrl"></iframe>
        </div>
    </div>
</div>