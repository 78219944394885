import {Pipe, PipeTransform} from '@angular/core';
import {CoinPackageOffer} from "../../core";

@Pipe({
    name: 'purchaseCoins'
})

export class PurchaseCoinPipe implements PipeTransform {
    transform(coins: CoinPackageOffer[]): CoinPackageOffer[] {
        if (!coins.length) return [];
        return coins
            .filter(coin => (typeof coin.price === 'number' && coin.price > 0) || (typeof coin.discountPrice === 'number' && coin.discountPrice > 0) && coin.price > coin.discountPrice)
            .map((coin, index) => ({
                ...coin,
                orderIndex: coin.orderIndex || index
            }))
            .sort((a, b) => a.orderIndex! - b.orderIndex!);
    }
}

@Pipe({
    name: 'claimCoins'
})

export class ClaimCoinPipe implements PipeTransform {
    transform(coins: CoinPackageOffer[]): CoinPackageOffer[] {
        if (!coins.length) return [];

        return coins
            .filter(coin => coin.price === 0 || coin.price === null)
            .map((coin, index) => ({
                ...coin,
                orderIndex: coin.orderIndex || index
            }))
            .sort((a, b) => a.orderIndex! - b.orderIndex!);
    }
}
