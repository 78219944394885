export enum GtmEvent {
    OpeningCoinStore = 'OpeningCoinStore',
    ClaimingFreeCoinPackage = 'ClaimingFreeCoinPackage',
    BuyingCoinPackage = 'BuyingCoinPackage',
    PurchaseSuccess = 'PurchaseSuccess',
    RegistrationSuccess = 'RegistrationSuccess',
    StartedKYC = 'StartedKYC',
    FinishedKYC = 'FinishedKYC',
    OpenedRedeemScreen = 'OpenedRedeemScreen',
    SubmittedRedeemRequest = 'SubmittedRedeemRequest',
    CompletedProfile = 'CompletedProfile',
    PurchaseFailed = 'PurchaseFailed'
}