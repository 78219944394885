import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CoinPackageOffer, NotificationService, PaymentType} from "../../../../core";
import {EVENT_TYPES} from "../../../constants";
import {takeUntil} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {EventBus} from "../../../../core/infrastructure";
import {TransactionStatus} from "../../../../core/enums/transaction";
import {PaymentsService} from "../../../../core/store";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-google-payment',
    templateUrl: './google-payment.component.html',
    styleUrls: ['./google-payment.component.scss']
})
export class GooglePaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private completeSubscription$: Subscription | null = null;

    public request: any = null;

    @Input()
    public coin!: CoinPackageOffer;

    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly eventBus: EventBus,
        private readonly paymentsService: PaymentsService,
        private readonly notificationService: NotificationService,
    ) {
    }

    get environment() {
        return environment.ENVIRONMENT === "production" ? "PRODUCTION" : "TEST"
    }

    get amount() {
        return (this.coin.discountPrice || this.coin.price).toFixed(2)
    }

    ngOnInit(): void {
        this.request = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: "CARD",
                    parameters: {
                        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                        allowedCardNetworks: ["AMEX", "VISA", "MASTERCARD"]
                    },
                    tokenizationSpecification: {
                        type: "PAYMENT_GATEWAY",
                        parameters: {
                            gateway: "checkoutltd",
                            gatewayMerchantId: environment.CHECKOUT_SETTINGS?.PUBLIC_KEY
                        }
                    }
                }
            ],
            merchantInfo: {
                merchantId: environment.GOOGLE_PAY_SETTINGS?.MERCHANT_ID,
                merchantName: environment.GOOGLE_PAY_SETTINGS?.MERCHANT_NAME
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: this.amount,
                currencyCode: 'USD',
                countryCode: 'US'
            }
        }
    }

    close() {
        this.onClose.emit();
        this.completeSubscription$?.unsubscribe();
    }

    onStart() {
        this.completeSubscription$ = this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.close();

            this.onComplete.emit({
                status: msg.status
            });
        })
    }

    onError($event: ErrorEvent) {
        console.log('Google Pay Error', $event)

        this.close();

        this.onComplete.emit({
            status: TransactionStatus.Failed
        });
    }

    onLoadPaymentData = (paymentData: google.payments.api.PaymentData) => {
        console.log('Google Pay Load', paymentData);

        const token = paymentData.paymentMethodData.tokenizationData.token;

        if (!token) return;

        this.paymentsService.initiateCheckoutPayment({
            coinPackageId: this.coin.id,
            token: token,
            type: PaymentType.GooglePay
        }).subscribe({
            next: (response) => {
                if (response.url) {
                    window.open(response.url, '_blank')
                }

                this.onComplete.emit({
                    status: TransactionStatus.Pending
                });
            },
            error: (response) => {
                this.onComplete.emit({
                    status: TransactionStatus.Failed,
                    message: response?.error?.detail ?? response
                });

                this.notificationService.showNotification({
                    type: 'error',
                    message: response?.error?.detail ?? response
                });
            }
        });

    };

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
