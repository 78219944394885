import {
    AfterViewChecked,
    Directive,
    ElementRef,
    Renderer2
} from '@angular/core';
import { ValidationState } from '../enums';

@Directive({ selector: '[material-control-validator]' })
export class MaterialControlValidatorDirective implements AfterViewChecked {
    constructor(
        private _el: ElementRef,
        private _renderer: Renderer2
    ) { }

    public ngAfterViewChecked(): void {
        this.checkIfInputHasError();
    }

    private checkIfInputHasError(): void {
        let input: HTMLInputElement = this._el.nativeElement;
        if (
            input.classList.contains(ValidationState.Invalid) &&
            input.classList.contains(ValidationState.Dirty)
        ) {
            this._renderer.removeClass(this._el.nativeElement, 'valid');
            this._renderer.addClass(this._el.nativeElement, 'invalid');
        } else if (
            input.classList.contains(ValidationState.Valid)
        ) {
            this._renderer.removeClass(this._el.nativeElement, 'invalid');
            this._renderer.addClass(this._el.nativeElement, 'valid');
        }
    }
}
