import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {SearchStore} from "./search.store";

@Injectable({providedIn: 'root'})
export class SearchQuery extends Query<any> {
  public allState$ = this.select();
  public isLoading$ = this.selectLoading();
  public error$ = this.selectError();
  public criteria$ = this.select(item => item.criteria);
  public isOpen$ = this.select(item => item.isOpen);

  constructor(
    private readonly _store: SearchStore
  ) {
    super(_store);
  }
}
