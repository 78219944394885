import {Injectable} from "@angular/core";
import {NavigationBarStore} from "./navigation-bar.store";
import {NavigationBarComponent} from "../../../shared";
import {ModalService} from "../../../shared/services";

@Injectable({providedIn: 'root'})
export class NavigationBarService {

    constructor(
        private readonly navigationBarStore: NavigationBarStore,
        private readonly modalService: ModalService
    ) {
    }

    public openModal(): void {
        const dialogRef = this.modalService.open(NavigationBarComponent, {
            enterAnimationDuration: 0,
            exitAnimationDuration: 0,
        });

        dialogRef.componentInstance.isModal = true;
    }

    public toggleMinimize(state: boolean): void {
        this.navigationBarStore.updateState(state);
    }
}
