import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  public type: 'default' | 'success' | 'normal' = 'default';

  @Input()
  public disabled: boolean = false;

  @Input()
  public text: string | number | null = '';

  @Input()
  public fullWidthMode: boolean = false;

  @Input()
  public icon: string | undefined = undefined;

  @Input()
  public iconPosition: 'before' | 'after' = 'after';

  @Output()
  public onButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.type = changes.type.currentValue;
    }
  }

  public onClick(): void {
    if (!this.disabled) {
      this.onButtonClicked.emit(true);
    }
  }

}