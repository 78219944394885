import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {NavigationLink} from "../../interfaces";

const createInitialState = (): CasinoLobbyStateEntity => {
  return {
    items: new Map<string, NavigationLink[]>(),
    currentPath: ""
  };
}

export interface CasinoLobbyStateEntity {
  items: Map<string, NavigationLink[]>,
  currentPath: string,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'casinoLobby', resettable: true})
export class NavigationLinksStore extends Store<CasinoLobbyStateEntity> {
  constructor() {
    super(createInitialState());
  }

  loadItems(type: string, links: NavigationLink[]) {
    this.update(state => {
      state.items.set(type, links);

      return state
    });
  }

  updateCurrentPath(path: string) {
    this.update({
      currentPath: path
    })
  }
}
