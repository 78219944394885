import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {CoinPackageCategory, CoinPackageOffer} from "../interfaces";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class CoinPackageProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getCategories(): Observable<CoinPackageCategory[]> {
        const url = `${environment.ENDPOINTS.API.URL}/category/offers`;
        return this.http.get<CoinPackageCategory[]>(url);
    }

    public claimCoin(coinPackageId: number): Observable<HttpResponse<any>> {
        const url = `${environment.ENDPOINTS.API.URL}/wallets/claim`;
        return this.http.post<HttpResponse<any>>(url, {
            coinPackageId
        });
    }

    public purchaseCoin(coinPackageId: number, token: string): Observable<boolean> {
        const url = `${environment.ENDPOINTS.API.URL}/wallets/purchase`;
        return this.http.post<boolean>(url, {
            coinPackageId,
            token
        });
    }

    public getCoinPackage(id: string): Observable<CoinPackageOffer> {
        const url = `${environment.ENDPOINTS.API.URL}/coin-package/${id}`;

        return this.http.get<CoinPackageOffer>(url);
    }
}
