import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AccountCommunication,
  AccountDetails,
  PasswordEntity
} from '../interfaces';
import {HttpClient, HttpResponse, HttpStatusCode} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AccountProvider {

  constructor(
      private readonly http: HttpClient
  ) {
  }

  public getAccountDetails(): Observable<AccountDetails> {
    const url = `${environment.ENDPOINTS.API.URL}/account/details`;

    return this.http.get<AccountDetails>(url);
  }

  public setAccountCommunication(communications: AccountCommunication): Observable<AccountCommunication> {
    const url = `${environment.ENDPOINTS.API.URL}/account/communication`;

    return this.http.post<AccountCommunication>(url, communications);
  }

  public checkEmail(email: string): Observable<HttpResponse<any>> {
    const url = `${environment.ENDPOINTS.API.URL}/account/email/verify`;

    return this.http.post<HttpResponse<any>>(url, {email});
  }
  
  public getNotifications(): Observable<any> {
    const url = `/api/notifications/push`;
    
    return this.http.get<any>(url);
  }

  public updateAccountDetails(details: AccountDetails): Observable<AccountDetails> {
    const url = `${environment.ENDPOINTS.API.URL}/account/details`;

    return this.http.post<AccountDetails>(url, details);
  }

  public updatePassword(passwordEntity: PasswordEntity): Observable<HttpStatusCode> {
    const url = `${environment.ENDPOINTS.API.URL}/account/password`;

    return this.http.post<HttpStatusCode>(url, passwordEntity)
  }
}
