import { Component } from '@angular/core';
import {ModalService} from "../../services";
import {RedeemRequest} from "../../../core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-redeem-cancel-summary',
  templateUrl: './redeem-summary.component.html',
  styleUrls: ['./redeem-summary.component.scss']
})
export class RedeemSummaryComponent {
  public isSuccess: boolean = false;
  public message: string = "";
  public request: RedeemRequest | null = null;

  constructor(
      private readonly modalService: ModalService,
      private readonly dialogRef: MatDialogRef<RedeemSummaryComponent>
  ) {
  }

  public closeAll(): void {
    this.modalService.closeAll()
  }

  public close(): void {
    this.dialogRef.close();
  }
}
