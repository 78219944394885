import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Banner, BannerSettings} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class BannerProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getBanner(id: number): Observable<Banner> {
        const url = `${environment.ENDPOINTS.CMS.URL}/banners/${id}`;

        return this.http.get<Banner>(url);
    }

    public getBannerSettings(): Observable<BannerSettings> {
        const url = `${environment.ENDPOINTS.CMS.URL}/settings/banners`;

        return this.http.get<BannerSettings>(url);
    }
}
