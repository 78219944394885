import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {LoyaltyLevel, PlayerLevel} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class LevelsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getLevels(): Observable<LoyaltyLevel[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/levels`;

        return this.http.get<LoyaltyLevel[]>(url);
    }

    public getPlayerLevel(userId: number): Observable<PlayerLevel> {
        const url = `${environment.ENDPOINTS.API.URL}/players-loyalty/${userId}`;

        return this.http.get<PlayerLevel>(url);
    }
}
