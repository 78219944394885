import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, NavigationLinksService} from "../../../core/store";
import {Router} from "@angular/router";
import {CoinPackageService} from "../../../core/services/coin-package.service";
import {switchMap, takeUntil, withLatestFrom} from "rxjs/operators";
import {Subject, combineLatest} from "rxjs";
import {ModalService} from "../../services";
import {SearchComponent} from "../../modals";
import {NavigationLink} from 'src/app/core';

@Component({
    selector: 'app-navigation-bar-mobile',
    templateUrl: './navigation-bar-mobile.component.html',
    styleUrls: ['./navigation-bar-mobile.component.scss']
})
export class NavigationBarMobileComponent implements OnInit, OnDestroy {
    public isAuthenticated: boolean = false;
    public isLoaded: boolean = false;
    public allLinks: NavigationLink[] = [];
    private destroy$ = new Subject<boolean>();

    constructor(
        private readonly _router: Router,
        private readonly _coinPackageService: CoinPackageService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly navigationLinksService: NavigationLinksService
    ) {
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
            switchMap((isAuthenticated) => {
                return isAuthenticated
                    ? this.navigationLinksService.getLinks('mobile-nav-authenticated')
                    : this.navigationLinksService.getLinks('mobile-nav-anonymous');
            })
        ).subscribe((links) => {
            this.allLinks = links;
            this.isLoaded = true;
        })
    }

    public navigate(link: NavigationLink): void {
        this.modalService.closeAll();

        switch (link.Title) {
            case 'Get Coins': {
                this._coinPackageService.openCoinStoreModal();
                break;
            }

            case 'Search': {
                this.modalService.open(SearchComponent)
                break;
            }

            default: {
                this._router.navigateByUrl(link.Url);
                break;
            }

        }

    }

    public hasAccent(title: NavigationLink["Title"]): boolean {
        return title === 'Get Coins' || title === 'Sign Up';
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }
}
