import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {GameItem, GameItemResponse, SessionResponse, UserGameBodyRequest} from 'src/app/core/interfaces';
import * as moment from "moment/moment";
import {DeviceDetectorService} from "ngx-device-detector";
import {UserInfoQuery, UserInfoService} from "../store";
import {Currency} from "../../shared";
import {LobbyGame} from "../interfaces/lobby";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class GamesProvider {
    protected readonly _ds = inject(DeviceDetectorService);

    constructor(
        private readonly http: HttpClient,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly userInfoService: UserInfoService,
    ) {
    }

    public getGamesByIdentifiers(identifiers: string[]): Observable<LobbyGame[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/games`;

        return this.http.post<LobbyGame[]>(url, {
            identifiers: identifiers
        });
    }

    public getGamesByCriteria(criteria: string): Observable<LobbyGame[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/games/search`;

        return this.http.post<LobbyGame[]>(url, {
            criteria: criteria
        });
    }

    public getGameByData(provider: string, slug: string): Observable<LobbyGame> {
        const url = `${environment.ENDPOINTS.CMS.URL}/games/${provider}/${slug}`;

        return this.http.get<LobbyGame>(url);
    }

    public getMyGames(playerId: number): Observable<GameItemResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/players/${playerId}/games`;

        return this.http.get<GameItemResponse>(url);
    }

    public addGame(game: GameItem): Observable<void> {
        const url = `${environment.ENDPOINTS.API.URL}/players/games`;

        return this.http.post<void>(url, game);
    }

    public removeGame(gameId: string, userId: number): Observable<void> {
        const url = `${environment.ENDPOINTS.API.URL}/players/${userId}/games/${gameId}`;

        return this.http.delete<void>(url);
    }

    public launchGame(game: LobbyGame): Observable<SessionResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/game`;

        return this.makeGameRequest(url, game);
    }

    public launchGameDemo(game: LobbyGame): Observable<SessionResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/game/demo`;

        return this.makeGameRequest(url, game);
    }

    private makeGameRequest(url: string, game: LobbyGame) {
        return combineLatest([
            this.userInfoService.getUser(),
            this.userInfoQuery.selectedCurrency$,
            this.userInfoService.getCurrentBalance()
        ]).pipe(
            switchMap(([userInfo, currency, balance]) => {
                if (userInfo === null) throw 'User not existed';

                const currencyText = Currency[currency];
                const balanceValue = +((balance?.balance || 0) * 100).toFixed(0);

                const user: UserGameBodyRequest = {
                    id: userInfo.id.toString(),
                    email: userInfo.email,
                    firstname: userInfo.firstName,
                    lastname: userInfo.lastName,
                    nickname: `${userInfo.firstName} ${userInfo.lastName}`,
                    city: userInfo.city,
                    country: userInfo.state,
                    date_of_birth: userInfo.birthday ? moment(userInfo.birthday).format('YYYY-MM-DD') : "",
                    registered_at: moment(userInfo.registrationTime).format('YYYY-MM-DD'),
                    gender: 'm'
                }

                const urls = {
                    return_url: `${environment.CURRENT_URL}`,
                    deposit_url: `${environment.CURRENT_URL}`
                }

                const body = {
                    game: game.Code,
                    contribute_to_wagering: game.ContributesToWagering ?? false,
                    game_type: game.Type?.Code,
                    locale: "en",
                    ip: "46.53.162.55",
                    client_type: this._ds.isDesktop() ? 'desktop' : 'mobile',
                    urls: urls,
                    currency: currencyText,
                    balance: balanceValue,
                    user: user
                }

                return this.http.post<SessionResponse>(url, body);
            })
        )
    }
}
