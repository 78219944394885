<a *ngIf="game" class="game" (click)="openGame()" [routerLink]="gameUrl">
    <div class="delete-icon" *ngIf="showDelete" (click)="delete($event)">
        <i class="icon close" aria-describedby="Delete"></i>
    </div>
    <div class="image-container">
        <div *ngIf="!isImageLoaded" class="skeleton-loading"></div>
        <img
                [attr.aria-label]="game.DisplayTitle"
                [class.hidden]="!isImageLoaded"
                [src]="game.Cover ? game.Cover.url : game.Code | casinoIcon:imgUrl"
                (error)="errorHandler($event)"
                (load)="onImageLoaded()"
                [alt]="game.DisplayTitle"
                width="100%"
                height="100%"
                loading="lazy"
        />
    </div>
    <div *ngIf="isAuthenticated && showFavorite" class="game__favorite" (click)="toggleFavorite($event)">
        <button class="btn btn-icon" *ngIf="!isFavourite else addedToFavourite">
            <img class="bookmark-icon" src="assets/images/bookmark.svg" alt="bookmark">
        </button>
        <ng-template #addedToFavourite>
            <button class="btn btn-icon">
                <img class="bookmark-icon" src="assets/images/bookmark-fill.svg" alt="bookmark filled">
            </button>
        </ng-template>
    </div>
    <div class="hover-view">
        <img src="assets/images/play-cricle.svg" alt="circle" width="68px" height="68px">
        <span *ngIf="game.Payout">RTP {{game.Payout}}%</span>
    </div>
</a>
<div class="game__title">{{game?.DisplayTitle}}</div>