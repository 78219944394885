import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {Game, GameItem, GameItemResponse} from "../../interfaces";

const createInitialState = (): GamesStateEntity => {
  return {
    myGames: {
      favoriteGames: [],
      recentGames: []
    } as GameItemResponse,
    myGamesLoaded: false,
    myGamesIsLoading: false,
  };
}

export interface GamesStateEntity {
  myGames: GameItemResponse,
  myGamesLoaded: boolean,
  myGamesIsLoading: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'casinoSearch', resettable: true})
export class GamesStore extends Store<GamesStateEntity> {
  constructor() {
    super(createInitialState());
  }

  setMyGames(myGames: GameItemResponse) {
    this.update(state => ({
      myGames,
      myGamesLoaded: true,
      myGamesIsLoading: false,
    }));
  }

  public setLoading(loading: boolean): void {
    this.update({
      myGamesIsLoading: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
