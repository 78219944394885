import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {BankDetailsStateEntry, BankDetailsStore} from "./bank-details.store";

@Injectable({providedIn: 'root'})
export class BankDetailsQuery extends Query<BankDetailsStateEntry> {
    public bankDetails$ = this.select(item => item.bankDetails);

    constructor(
        private readonly _store: BankDetailsStore
    ) {
        super(_store);
    }

}
