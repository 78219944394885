<div class="modal" *ngIf="cardDetails">
    <div class="modal__header">
        <h1>Player details</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>
    <div class="modal__content">
        <div class="header">
            <div class="header__icon">
                <i class="u_user-circle"></i>
            </div>
            <div class="header__container">
                <div class="username">
                    {{ cardDetails.nickname }}
                </div>
                <div class="address">
                    {{ cardDetails.state }}, {{ cardDetails.country }}
                </div>
            </div>
        </div>
        <div class="details">
            <div class="details-item">
                <div class="icon-container">
                    <i class="u_award"></i>
                </div>
                <div class="title">Level {{ cardDetails.level }}</div>
                <div class="value">{{ cardDetails.currentPoints }} Points</div>
            </div>
        </div>
    </div>
    <div class="modal__actions">
        <button class="btn btn-large btn-outlined" (click)="close()">Close</button>
    </div>
</div>