export enum CryptoType {
    BTC,
    ETH,
    LTC,
    BCH,
    USDC,
    DASH,
    TRX,
    USDT,
    USDT_TRC20
}

export const CryptoTypeLabel = new Map<number, string>([
    [CryptoType.BTC, 'Bitcoin'],
    [CryptoType.ETH, 'Ethereum'],
    [CryptoType.LTC, 'Litecoin'],
    [CryptoType.BCH, 'Bitcoin Cash'],
    [CryptoType.USDC, 'USD Coin'],
    [CryptoType.DASH, 'Dash'],
    [CryptoType.TRX, 'Tron'],
    [CryptoType.USDT, 'Tether USD (ERC20)'],
    [CryptoType.USDT_TRC20, 'Tether USD (TRC20)'],
])