import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {GamesStateEntity, GamesStore} from "./games.store";

@Injectable({providedIn: 'root'})
export class GamesQuery extends Query<GamesStateEntity> {
  public myGamesLoaded$ = this.select(item => item.myGamesLoaded);
  public myGamesIsLoading$ = this.select(item => item.myGamesIsLoading);
  public myGames$ = this.select(item => item.myGames);

  constructor(
    private readonly _gamesStore: GamesStore,
  ) {
    super(_gamesStore);
  }
}
