import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hideCard'
})

export class HideCard implements PipeTransform {
    transform(value: number, visibleDigits: number, space?: boolean): string {
        let maskedNumbers = value.toString().slice(0, -visibleDigits);
        const visibleNumbers = value.toString().slice(-visibleDigits);
        const transformedValue = maskedNumbers.replace(/./g, '*') + visibleNumbers;
        return space ? this.valueWithSpaces(transformedValue) : transformedValue;
    }

    private valueWithSpaces(value: string): string {
        return [...value].map((chr, idx) => (idx + 1) % 4 ? chr : chr + ' ').join('').trim();
    }
}