import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPayPalConfig} from "ngx-paypal";
import {CoinPackageOffer, NotificationService, OrdersProvider} from "../../../../core";
import {map} from "rxjs/operators";
import {TransactionStatus} from "../../../../core/enums/transaction";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-paypal-payment',
    templateUrl: './paypal-payment.component.html',
    styleUrls: ['./paypal-payment.component.scss']
})
export class PaypalPaymentComponent implements OnInit {
    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public coin!: CoinPackageOffer;

    public payPalConfig?: IPayPalConfig;

    constructor(
        private readonly ordersProvider: OrdersProvider,
        private readonly notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.initConfig();
    }

    private initConfig(): void {
        this.payPalConfig = {
            clientId: environment.PAYPAL_SETTINGS?.CLIENT_ID!,
            fundingSource: "PAYPAL",
            style: {
                label: 'paypal',
                color: 'blue',
                shape: 'rect',
                layout: 'vertical'
            },
            createOrderOnServer: () => {
                return this.ordersProvider.createOrder({
                    coinPackageId: this.coin.id
                }).pipe(
                    map((order) => order.id)
                ).toPromise()
            },
            onApprove: (data, actions) => {
                actions.order.get().then((details: any) => {
                    this.onComplete.emit({
                        status: TransactionStatus.Succeeded
                    })
                });
            },
            onError: err => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: err.error.detail
                })
            }
        };
    }
}
