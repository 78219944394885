import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
    AccountDetails,
    BirthdayValidators, Country, CountryState,
    PhoneValidators,
    ZipcodeValidators,
} from "../../../core";
import {takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {combineLatest, Subject} from "rxjs";
import {AccountService, CountriesService} from "../../../core/store";

@Component({
    selector: 'app-user-details-form',
    templateUrl: './user-details-form.component.html',
    styleUrls: ['./user-details-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsFormComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Input() showTerms = true;

    @Input()
    public set disableSaveBtn(value: boolean) {
        this._disableSaveBtn = value;
        if (value && this.initialDetails && this.form.value) {
            this.initialDetails = JSON.parse(JSON.stringify(this.form.value));
        }
    }

    @Output() details: EventEmitter<AccountDetails | null> = new EventEmitter();
    @Output() disableSaveBtnEmitter: EventEmitter<boolean> = new EventEmitter(true);

    public form!: UntypedFormGroup;
    public countries: any[] = [];
    public states: CountryState[] = [];
    public countryCodes: any[] = [];
    public hasDetails = false;
    public isLoading = false;
    public maxDate: any = new Date();
    public minDate: any = new Date(Date.UTC(1900, 0, 1));
    public initialDetails: AccountDetails | null = null;
    private _disableSaveBtn = true;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly countriesService: CountriesService,
    ) {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngOnInit(): void {
        this.buildForm();

        this.form.controls['country'].valueChanges.pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.countriesService.getCountries()),
            tap(([country, countries]) => {
                if (!country) return;

                const currentCountry = countries.find((c: Country) => c.Code === country);

                if (!currentCountry) return;

                this.form.get('state')?.setValue("");
                this.states = currentCountry.States;

                this.form.get('countryCode')?.setValue(this.countryCodes.filter(code => code.value === currentCountry.Code)[0]);
                this.setDisableBtnState();
            })
        ).subscribe()

        combineLatest([
            this.countriesService.getCountries(),
            this.accountService.getDetails(),
            this.accountService.hasDetails(),
        ]).subscribe(([countries, details, hasDetails]) => {
            if (countries.length > 0 && typeof details  != undefined && typeof hasDetails  != undefined)
            {
                this.hasDetails = hasDetails;
                this.prepareCountries(countries);
                this.prepareDetails(details);
            }
        })

        this.form.valueChanges.pipe(
            takeUntil(this.destroy$),
        ).subscribe(() => {
            this.setDisableBtnState();
            if (!this.form.valid) {
                this.details.emit(null);
                return;
            }

            let details: any = {
                username: this.form.get('username')?.value,
                phone: this.form.get('phone')?.value.toString(),
            };

            if (!this.hasDetails) {
                details = {
                    ...details,
                    firstName: this.form.get('firstName')?.value,
                    lastName: this.form.get('lastName')?.value,
                    birthday: new Date(this.form.get('birthday')?.value).toISOString(),
                    country: this.form.get('country')?.value,
                    street: this.form.get('address')?.value,
                    zipCode: this.form.get('postcode')?.value,
                    city: this.form.get('city')?.value,
                    state: this.form.get('state')?.value,
                }
            }

            this.details.emit(details)
        })
    }

    public getErrorMessage(formControl: any) {
        if (formControl.hasError('required'))
            return 'This field is required.';

        if (formControl.hasError('notEnoughYears'))
            return 'You must be at least 18 years old.'
        if (formControl.hasError('notEnoughDigits'))
            return 'Please use a year higher than 1900.'

        if (formControl.hasError('notContainTenDigits'))
            return 'Your phone number should be exactly ten digits.'

        if (formControl.hasError('notContainFiveDigits'))
            return 'Invalid ZIP code. Please enter a 5-digit number.'

        return ""
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            username: [null, [
                Validators.required,
                Validators.maxLength(12),
                Validators.minLength(3)
            ]],
            firstName: [null, [
                Validators.required,
                Validators.maxLength(35)
            ]],
            lastName: [null, [
                Validators.required,
                Validators.maxLength(35)
            ]],
            postcode: [null, [
                Validators.required,
                ZipcodeValidators.checkIfContainFiveDigits
            ]],
            address: [null, [
                Validators.required,
            ]],
            city: [null, [
                Validators.required,
            ]],
            state: [null],
            country: [null, [
                Validators.required,
            ]],
            phone: [null, [
                Validators.required,
                PhoneValidators.checkIfContainTenDigits
            ]],
            birthday: [null, [
                Validators.required,
                BirthdayValidators.checkClientAge
            ]],
            age: [false, []],
            countryCode: [null, [
                Validators.required,
            ]],
        })
    }

    private prepareCountries(countries: Country[]) {
        this.countries = countries.map((c: Country) => ({
            name: c.Title,
            code: c.Code
        }));

        this.countryCodes = countries.map((c: Country) => ({
            value: c.Code,
            text: c.PhoneCode,
            icon: c.Icon.url,
        }));

        if (this.countries.length > 0)
            this.form.get('country')?.setValue(this.countries[0].code);

        if (this.countryCodes.length > 0)
            this.form.get('countryCode')?.setValue(this.countryCodes[0]);
    }

    private prepareDetails(details: AccountDetails | null) {
        if (!details) return;

        this.form.patchValue({
            username: details.username,
            firstName: details.firstName,
            lastName: details.lastName,
            birthday: details.birthday,
            country: details.country ?? this.countries[0].code,
            address: details.street,
            postcode: details.country && details.street && details.state ? details.zipCode : null,
            city: details.country && details.street && details.state ? details.city : null,
            state: details.state,
            phone: details.phone
        })

        if (!this.hasDetails) {
            this.form.get('country')?.setValue('')
            this.form.get('zipCode')?.setValue('')
            this.form.get('city')?.setValue('')
            this.form.get('state')?.setValue('')
        }

        if (!this.initialDetails) {
            this.initialDetails = { ...this.form.value };
        }
    }

    private setDisableBtnState(): void {
        if (this.initialDetails) {
            const isFormChanged = JSON.stringify(this.form.value) !== JSON.stringify(this.initialDetails);
            const saveBtnFlagState = !isFormChanged || !this.form.valid;
            if (saveBtnFlagState !== this._disableSaveBtn) {
                this._disableSaveBtn = saveBtnFlagState;
                this.disableSaveBtnEmitter.emit(this._disableSaveBtn);   
            }
        }
    }
}
