import {Component, Input, OnInit} from '@angular/core';
import {Currency, CurrencyLabel} from "../../constants";
import {AuthService, UserInfoQuery, UserInfoService} from "../../../core/store";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {toFixedIfNeeded} from "../../../core";
import {CoinPackageService} from '../../../core/services/coin-package.service';

@Component({
  selector: 'app-toggle-coins',
  templateUrl: './toggle-coins.component.html',
  styleUrls: ['./toggle-coins.component.scss']
})
export class ToggleCoinsComponent implements OnInit {
  private destroy$ = new Subject<boolean>();

  @Input() isMinimized: boolean = false;
  @Input() isGameView: boolean = false;
  @Input() hideTitle: boolean = false;
  @Input() horizontalView: boolean = false;
  @Input() balancePosition: 'top' | 'bottom' | 'right' = 'bottom';
  @Input() isMobileViewGame: boolean = false;
  @Input() isLandscapeView: boolean = false;

  public balance: string = "";
  public isAuthenticated: boolean = false;
  public currency: Currency = Currency.SWPC;
  public currencies = [
    Currency.SWPC,
    Currency.LUKC,
  ]

  constructor(
      private readonly userInfoService: UserInfoService,
      private readonly userInfoQuery: UserInfoQuery,
      private readonly authService: AuthService,
      private readonly coinPackageService: CoinPackageService
  ) {
  }

  ngOnInit(): void {
    this.authService.isAuthenticated().pipe(
        takeUntil(this.destroy$)
    ).subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    })

    this.userInfoService.getCurrentBalance().pipe(
        takeUntil(this.destroy$)
    ).subscribe((b) => {
      this.balance = toFixedIfNeeded(b === undefined ? 0 : b.balance + b.lockedBalance);
    })

    this.userInfoQuery.selectedCurrency$.pipe(
        takeUntil(this.destroy$)
    ).subscribe((currency) => {
      this.currency = currency
    })
  }

  public getCurrency(value: number) {
    return CurrencyLabel.get(value);
  }

  public toggleCoins() {
    this.userInfoService.switchCurrency()
  }

  public openStore() {
    this.coinPackageService.openCoinStoreModal();
  }

  ngOnDestroy(): void {
    this.destroy$?.next(true);
    this.destroy$?.complete();
  }
}