import {Injectable} from "@angular/core";
import {AccountStore} from "./account.store";
import {Observable, switchMap} from "rxjs";
import {AccountDetails} from "../../interfaces";
import {map, take, tap, withLatestFrom} from "rxjs/operators";
import {AccountQuery} from "./account.query";
import {NotificationService} from "../../services";
import {AccountProvider, AuthProvider} from "../../providers";
import {UserInfoService} from "../user";
import {AuthService} from "../auth";

@Injectable({providedIn: 'root'})
export class AccountService {

    constructor(
        private readonly accountStore: AccountStore,
        private readonly accountQuery: AccountQuery,
        private readonly accountProvider: AccountProvider,
        private readonly notificationService: NotificationService,
        private readonly userInfoService: UserInfoService,
        private readonly _authProvider: AuthProvider,
        private readonly authService: AuthService,
    ) {
    }

    public getDetails(): Observable<AccountDetails | null> {
        return this.accountQuery.details$.pipe(
            withLatestFrom(
                this.accountQuery.detailsLoaded$,
                this.accountQuery.detailsIsLoading$,
                this.authService.isAuthenticated(),
            ),
            tap(([_, loaded, loading, isAuthenticated]) => {
                if (isAuthenticated && !loaded && !loading) {
                    this.loadDetails();
                }
            }),
            map(([details, loaded]) => {
                return loaded ? details : null;
            })
        )
    }

    public loadDetails(): void {
        this.accountStore.setDetailsLoading(true);

        this.accountProvider.getAccountDetails().subscribe((details) => {
            this.accountStore.setDetails(details);
        })
    }

    public updateDetails(newDetails: any): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            this.getDetails().pipe(
                take(1),
                tap(async (details) => {
                    this.accountProvider.updateAccountDetails({
                        ...details,
                        ...newDetails
                    }).subscribe({
                        next: (result) => {
                            if (result == undefined) return

                            this.accountStore.setDetails(result);
                            this.userInfoService.clear();

                            resolve(true);
                        },
                        error: (response) => {
                            this.notificationService.showNotification({
                                type: 'error',
                                message: response.error.detail
                            });
                            resolve(false);
                        }
                    })
                }),
                switchMap(() => this.refreshToken())
            ).subscribe()
        });
    }

    public hasDetails(): Observable<boolean> {
        return this.getDetails().pipe(
            map((details) => {
                if (!details) return false;

                return !!details.firstName
                    && !!details.birthday
                    && !!details.country
                    && !!details.street
                    && !!details.zipCode
                    && !!details.city
                    && !!details.state
                    && !!details.lastName;
            })
        )
    }

    public refreshToken() {
        return this.authService.token().pipe(
            take(1),
            switchMap((token) => {
                const refreshToken = token?.refresh_token!;

                return this._authProvider.refresh(refreshToken).pipe(
                    tap((token) => {
                        this.authService.setContext(token)
                    })
                )
            })
        )
    }

    public clear(): void {
        this.accountStore.clear();
    }
}
