<div class="bank-details-content">
    <div class="bank-details-logo">
        <img class="" src="assets/images/bank-details.png" alt="bank-details">
    </div>
    <div class="bank-details-content__description">
        Please provide details for a bank account that you own and would like to use to redeem your coins.
        The account you nominate will be used for this and all future prize redemptions.
    </div>
    <form [formGroup]="form">
        <div class="form-control-group">
            <div>Account Type</div>
            <mat-radio-group
                    class="material-block material-block__radio"
                    formControlName="accountType">
                <mat-radio-button class="example-radio-button" *ngFor="let type of bankAccountTypeList"
                                  [value]="type.value">
                    {{type.text}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <ng-container *ngLet="form.get('abaRoutingNumber') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__input"
                    hideRequiredMarker="true"
            >
                <mat-label>ABA Routing Number</mat-label>
                <input matInput formControlName="abaRoutingNumber" pattern="[a-zA-Z0-9]*">
                <mat-icon class="icon-gray" matSuffix>
                    {{formInput.invalid ? 'error' : 'check_circle'}}
                </mat-icon>
                <mat-error
                        *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngLet="form.get('bankName') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__input"
                    hideRequiredMarker="true">
                <mat-label>Bank Name</mat-label>
                <input matInput formControlName="bankName" pattern="[a-zA-Z0-9 ]*">
                <mat-icon class="icon-gray" matSuffix>
                    {{formInput.invalid ? 'error' : 'check_circle'}}
                </mat-icon>
                <mat-error
                        *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngLet="form.get('bankAccountNumber') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__input"
                    hideRequiredMarker="true"
            >
                <mat-label>Bank Account Number</mat-label>
                <input matInput formControlName="bankAccountNumber" pattern="[a-zA-Z0-9]*">
                <mat-icon class="icon-gray" matSuffix>
                    {{formInput.invalid ? 'error' : 'check_circle'}}
                </mat-icon>
                <mat-error
                        *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngLet="form.get('accountHolderName') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__input"
                    hideRequiredMarker="true"
            >
                <mat-label>Account Holder Name</mat-label>
                <input matInput formControlName="accountHolderName" pattern="[a-zA-Z0-9 ]*">
                <mat-icon class="icon-gray" matSuffix>
                    {{formInput.invalid ? 'error' : 'check_circle'}}
                </mat-icon>
                <mat-error
                        *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
        <div class="form-info">
            <h3>Bank statement</h3>
            <div>
                Please upload a a bank statement proving ownership of the bank account you nominate for prize
                redemptions.
            </div>
            <p>
                Please note:
            </p>
            <ul>
                <li>You can upload the following file formats: .jpg, .jpeg, .pdf, .png.
                    The maximum file size is 10MB.
                </li>
                <li>The statement must be for the bank account you want to nominate.
                    The statement must include your current address.
                </li>
                <li>The address on the statement must match the address in your account.
                    The document must be dated within the last 3 months.
                </li>
                <li>The entire document must be visible and readable.
                    Do not write on, draw or make any alterations to the document.
                </li>
            </ul>
        </div>

        <div class="upload-container">
            <ng-container *ngIf="currentFile === null; else selectedFile">
                <button (click)="imgFileInput.click()" class="btn btn-large btn-block">
                    <span class="mr-18">Upload bank statement</span>
                </button>
                <input hidden accept="image/*,.pdf" type="file" (change)="onFileChanged($event)" #imgFileInput/>
            </ng-container>
            <ng-template #selectedFile>
                <div class="file-name-container">
                    <i class="u_file" aria-describedby="File icon"></i>
                    <div class="file">{{currentFile.name}}</div>
                    <i class="u_trash" aria-describedby="Remove" (click)="removeFile()"></i>
                </div>
            </ng-template>
        </div>
        <button [disabled]="!canSubmit" class="btn btn-large btn-block mb-16" (click)="submit()">
            <span>Continue</span>
            <i class="u_arrow-circle-right ml-16" aria-describedby="Arrow right"></i>
        </button>
        <button class="btn btn-outlined btn-large btn-block" (click)="cancel()">
            <span>Cancel</span>
        </button>
    </form>
</div>