import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {PaymentsStateEntity, PaymentsStore} from "./payments.store";

@Injectable({providedIn: 'root'})
export class PaymentsQuery extends Query<PaymentsStateEntity> {
    public providersLoaded$ = this.select(item => item.providersLoaded);
    public providersIsLoading$ = this.select(item => item.providersIsLoading);
    public providers$ = this.select(item => item.providers);

    constructor(
        private readonly paymentsStore: PaymentsStore,
    ) {
        super(paymentsStore);
    }
}
