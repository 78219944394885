import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Breakpoint, NavigationService} from 'src/app/core';

@Component({
    selector: 'app-common-header',
    templateUrl: './common-header.component.html',
    styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
    @Input() showBackButton: boolean = true;
    @Input() customCloseHandler: boolean = false;

    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    public isMobileView: boolean = false;

    constructor(
        private _nav: NavigationService
    ) {
    }

    public ngOnInit(): void {
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        const viewPortWidth = window.innerWidth;
        this.isMobileView = viewPortWidth < Breakpoint.phablet;
    }

    public close(): void {
        if(!this.customCloseHandler)
            this._nav.navigateTo('');

        this.onClose.emit()
    }

    goBack() {
        this._nav.navigateBack();
    }
}
