import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JsEventHelper {
    public static OnPseudoElementClick(e: any, targetId: string, pseoudoElement: 'before' | 'after', callBack: Function) {
        const target = e.target || e.srcElement;
        if (target.getAttribute('id') !== targetId)
            return;

        const after = getComputedStyle(target, `:${pseoudoElement}`)
        if (after) {
            const atop = Number(after.getPropertyValue("top").slice(0, -2))
            const aheight = Number(after.getPropertyValue("height").slice(0, -2))
            const aleft = Number(after.getPropertyValue("left").slice(0, -2))
            const awidth = Number(after.getPropertyValue("width").slice(0, -2))
            const ex = e.layerX
            const ey = e.layerY
            if (ex > aleft && ex < aleft + awidth && ey > atop && ey < atop + aheight) {
                callBack()
            }
        }
    }
}