import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {UserInfoStateEntry, UserInfoStore} from "./user.store";
import {combineLatest, Observable} from "rxjs";
import {Game, UserBalanceDetails} from "../../interfaces";
import {filter, map, tap, withLatestFrom} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class UserInfoQuery extends Query<UserInfoStateEntry> {
  public allState$ = this.select();
  public userLoaded$ = this.select(item => item.userLoaded);
  public userIsLoading$ = this.select(item => item.userIsLoading);
  public balancesLoaded$ = this.select(item => item.balancesLoaded);
  public balancesIsLoading$ = this.select(item => item.balancesIsLoading);
  public user$ = this.select(item => item.user);
  public selectedCurrency$ = this.select(item => item.selectedCurrency);
  public balances$ = this.select(item => item.balances);
  public hasCoinArea$ = this.select(item => item.hasCoinArea);

  constructor(
    private readonly _store: UserInfoStore
  ) {
    super(_store);
  }
}
