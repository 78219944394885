import { AbstractControl } from '@angular/forms';

export class PhoneValidators {
  static checkIfContainTenDigits(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (!control.value) return null;
    if (control.value.toString().length !== 10 || isNaN(control.value)) {
      return { 'notContainTenDigits': true };
    } else {
      return null;
    }
  }
}