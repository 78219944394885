import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {KycUrlResponse} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class KycProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getKycUrl(): Observable<KycUrlResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/kyc`;

        return this.http.get<KycUrlResponse>(url);
    }
}
