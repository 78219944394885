<div class="modal">
    <div class="modal__header">
        <div></div>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>
    <div *ngIf="isLC" class="modal__content">
        <h3 class="info-text">Go to the coin store to get more Lucky Coins Packages</h3>
    </div>
    <div *ngIf="!isLC" class="modal__content">
        <h3 class="info-text">Not enough Coins!</h3>
        <p class="message">Consider lowering your play amount or selecting a different game.</p>
        <p class="message">You can get more free Sweep Coins by doing any of the following:</p>
        <ul>
            <li>Get free Sweep Coins with a purchase of Lucky Coins</li>
            <li>Win a competition</li>
            <li>Send a Request Card in accordance with the Sweeps Rules</li>
        </ul>
    </div>

    <div class="modal__actions">
        <button class="btn btn-large" (click)="openStore()">
            <span>Go to Coin Store</span>
        </button>
    </div>
</div>