import {Injectable} from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EventBus {
  private readonly events = new Map<string, Subject<any>>();

  public Publish<T>(type: string, message?: T): void {
    this.GetOrCreateEvent(type).next(message);
  }

  public OnChange<T>(type: string): Observable<T> {
    return this.GetOrCreateEvent(type).asObservable();
  }

  public OnChanges<T>(...types: string[]): Observable<T> {
    return merge(...types.map((t) => this.OnChange<T>(t)));
  }

  private GetOrCreateEvent(type: string): Subject<any> {
    return (this.events.get(type) ??
      this.events.set(type, new Subject()).get(type)) as Subject<any>;
  }
}
