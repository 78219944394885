<article class="games-block">
    <header class="header">
        <div class="header__left">
            <div class="title">{{ headerTitle }}</div>
        </div>
        <div class="header__right" *ngIf="!vertical && games.length > itemsPerPage">
            <button class="btn btn-icon arrow-left" aria-label="Previous" (click)="goPrev($event)"
                    [disabled]="!canGoPrev">
                <i class="u_arrow-left" aria-describedby="Arrow left"></i>
            </button>
            <button class="btn btn-icon arrow-right" aria-label="Next" (click)="goNext($event)" [disabled]="!canGoNext">
                <i class="u_arrow-right" aria-describedby="Arrow right"></i>
            </button>
        </div>
    </header>
    <div class="container"
         [class.vertical]="vertical"
         [class.loading]="loading"
         (scroll)="onScroll($event)"
    >
        <div class="game-item" *ngFor="let game of games">
            <app-game-card [game]="game"></app-game-card>
        </div>
        <div class="game-item placeholder" *ngFor="let i of [].constructor(9)">
            <div class="game-image-placeholder skeleton-loading"></div>
            <div class="game-title-placeholder skeleton-loading"></div>
        </div>
    </div>
    <div *ngIf="!loading && games.length === 0" class="empty-info">
        There is no games to show
    </div>
</article>