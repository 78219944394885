import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {switchMap, takeUntil, tap} from "rxjs/operators";
import {combineLatest, of, Subject} from "rxjs";
import {CollectionsProvider, NavigationLink, NavigationService} from "../../../core";
import {AuthService, NavigationLinksQuery, NavigationLinksService} from "../../../core/store";
import {LobbyCategory} from "../../../core/interfaces/lobby";

@Component({
    selector: 'app-game-tabs',
    templateUrl: './game-tabs.component.html',
    styleUrls: ['./game-tabs.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class GameTabsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Input() customPath: boolean = false;

    public links: NavigationLink[] = [];
    public selectedLink: string = "";
    public isAuthenticated: boolean = false;
    public isLoading = true;
    public categories: LobbyCategory[] = [];

    constructor(
        private readonly navigationLinksService: NavigationLinksService,
        private readonly navigationService: NavigationService,
        private readonly authService: AuthService,
        private readonly navigationLinksQuery: NavigationLinksQuery,
        private readonly collectionsProvider: CollectionsProvider,
    ) {
    }

    public ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
        ).subscribe((isAuthenticated) => {
            this.isAuthenticated = isAuthenticated;
        })

        combineLatest([
            this.navigationLinksQuery.currentPath$,
            this.navigationLinksService.getLinks("casino-navigation")
        ]).pipe(
            takeUntil(this.destroy$),
            switchMap(([path, links]) => {
                this.isLoading = true;
                this.links = links;

                if (this.customPath) {
                    return of();
                }

                this.selectedLink = !path ? links[0].Url : path;

                const currentLink = links.find(l => l.Url === this.selectedLink);

                return this.collectionsProvider.getCollection(currentLink!.Collection.Slug)
            }),
            tap((collection) => {
                this.categories = [];
                this.categories = collection.Categories;

                this.isLoading = false;
            })
        ).subscribe();
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public setActiveState(path: string, link: string): boolean {
        return path.includes(link);
    }

    public change(url: string): void {
        this.navigationLinksService.updateCurrentPath(url);

        if (!this.customPath) return;

        if (this.isAuthenticated) {
            this.navigationService.navigateTo('games')
        } else {
            this.navigationService.navigateTo('')
        }
    }
}
