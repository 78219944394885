import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CheckoutOrderRequest,
  CheckoutOrderResponse,
  FlykkOrderRequest,
  ForumPayRatesRequest,
  ForumPayRequest,
  ForumPayResponse,
  NuveiIbtOrderResponse,
  NuveiOrderResponse,
  OrderRequest,
  PayNearMeOrderResponse,
} from '../interfaces';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class OrdersProvider {
  constructor(private readonly http: HttpClient) {}

  public createOrder(data: OrderRequest): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/orders`;

    return this.http.post<any>(url, data);
  }

  public createFlykkOrder(data: FlykkOrderRequest): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/flykk`;

    return this.http.post<any>(url, data);
  }

  public createNuveiOrder(data: OrderRequest): Observable<NuveiOrderResponse> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/nuvei`;

    return this.http.post<NuveiOrderResponse>(url, data);
  }

  public createNuveiIbtOrder(
    data: OrderRequest
  ): Observable<NuveiIbtOrderResponse> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/nuvei-ibt`;

    return this.http.post<NuveiIbtOrderResponse>(url, data);
  }

  public createForumPayOrder(data: ForumPayRequest): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/forum-pay`;

    return this.http.post<any>(url, data);
  }

  public createCheckoutOrder(
    data: CheckoutOrderRequest
  ): Observable<CheckoutOrderResponse> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/checkout`;

    return this.http.post<CheckoutOrderResponse>(url, data);
  }

  public createPayNearMeOrder(
    data: OrderRequest
  ): Observable<PayNearMeOrderResponse> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/paynearme`;

    return this.http.post<any>(url, data);
  }

  public cancelPayNearMeOrder(data: OrderRequest): Observable<void> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/paynearme/cancel`;

    return this.http.put<void>(url, data);
  }

  public getForumPayRates(
    data: ForumPayRatesRequest
  ): Observable<ForumPayResponse> {
    const url = `${environment.ENDPOINTS.API.URL}/orders/forum-pay/rate`;

    return this.http.post<ForumPayResponse>(url, data);
  }
}
