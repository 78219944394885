import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthFacade, NavigationService} from "src/app/core";
import {AuthService} from "../../../core/store";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ModalService} from "../../services";

@Component({
    selector: 'app-time-limit-reached',
    templateUrl: './time-limit-reached.component.html',
    styleUrls: ['./time-limit-reached.component.scss']
})
export class TimeLimitReachedComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Input() limit!: number;

    constructor(
        private readonly navigationService: NavigationService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly authFacade: AuthFacade
    ) {
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
        ).subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.authFacade.logout();
            }
        })
    }

    public goToLogoutPage(): void {
        this.navigationService.navigateAndRefreshTo('');
        this.modalService.closeAll();
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }
}