import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {AuthStore, AuthStateEntity} from "./auth.store";

@Injectable({providedIn: 'root'})
export class AuthQuery extends Query<AuthStateEntity> {
  public token$ = this.select(item => item.token);
  public tokenLoaded$ = this.select(item => item.tokenLoaded);
  public tokenIsLoading$ = this.select(item => item.tokenIsLoading);

  constructor(
    private readonly _store: AuthStore
  ) {
    super(_store);
  }
}
