import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {LoyaltyLevel, PlayerLevel} from "../../../core";
import {LevelsService} from "../../../core/store";
import {takeUntil, tap} from "rxjs/operators";
import {ModalService} from "../../services";

@Component({
    selector: 'app-player-level-details-modal',
    templateUrl: './player-level-details-modal.component.html',
    styleUrls: ['./player-level-details-modal.component.scss']
})
export class PlayerLevelDetailsModalComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public level: LoyaltyLevel | null = null;
    public playerLevel: PlayerLevel | null = null;

    get levelNumber() {
        return this.playerLevel === null ? 0 : this.playerLevel.level
    }

    constructor(
        public readonly levelsService: LevelsService,
        public readonly modalService: ModalService,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    ngOnInit(): void {
        if (this.playerLevel !== null) {
            this.levelsService.getLevelByNumber(this.playerLevel?.level)
                .pipe(
                    takeUntil(this.destroy$),
                    tap((level) => {
                        this.level = level;
                    })
                )
                .subscribe()
        }
    }

    public close(): void {
        this.modalService.closeAll();
    }
}
