import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {CasinoLobbyStateEntity, NavigationLinksStore} from "./navigation-links.store";

@Injectable({providedIn: 'root'})
export class NavigationLinksQuery extends Query<CasinoLobbyStateEntity> {
  public items$ = this.select(item => item.items);
  public currentPath$ = this.select(item => item.currentPath);

  constructor(
    private readonly _store: NavigationLinksStore
  ) {
    super(_store);
  }
}
