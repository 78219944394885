import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {Country} from "../../interfaces";

const createInitialState = (): CountriesStateEntity => {
  return {
    countries: [],
    countriesLoaded: false,
    countriesIsLoading: false,
  };
}

export interface CountriesStateEntity {
  countries: Country[],
  countriesLoaded: boolean,
  countriesIsLoading: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'casinoSearch', resettable: true})
export class CountriesStore extends Store<CountriesStateEntity> {
  constructor() {
    super(createInitialState());
  }

  setCountries(countries: Country[]) {
    this.update(state => ({
      countries,
      countriesLoaded: true,
      countriesIsLoading: false,
    }));
  }

  public setLoading(loading: boolean): void {
    this.update({
      countriesIsLoading: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
