import {Injectable} from "@angular/core";
import {CountriesStore} from "./countries.store";
import {CountriesProvider} from "../../providers";
import {Country} from "../../interfaces";
import {map, tap, withLatestFrom} from "rxjs/operators";
import {Observable} from "rxjs";
import {CountriesQuery} from "./countries.query";

@Injectable({providedIn: 'root'})
export class CountriesService {
    constructor(
        private readonly countriesQuery: CountriesQuery,
        private readonly countriesStore: CountriesStore,
        private readonly countriesProvider: CountriesProvider,
    ) {
    }

    public getCountries(): Observable<Country[]> {
        return this.countriesQuery.countries$.pipe(
            withLatestFrom(
                this.countriesQuery.countriesLoaded$,
                this.countriesQuery.countriesIsLoading$,
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadCountries();
                }
            }),
            map(([countries]) => {
                return countries;
            })
        )
    }

    public loadCountries(): void {
        this.countriesStore.setLoading(true)

        this.countriesProvider.getAll().subscribe((countries) => {
            this.countriesStore.setCountries(countries)
        })
    }
}
