import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {CmsSettingsStore, CmsSettingsStateEntity} from "./cms-settings.store";

@Injectable({providedIn: 'root'})
export class CmsSettingsQuery extends Query<CmsSettingsStateEntity> {
  public settings$ = this.select(item => item.settings);
  public settingsLoaded$ = this.select(item => item.settingsLoaded);
  public settingsIsLoading$ = this.select(item => item.settingsIsLoading);

  constructor(
    private readonly cmsSettingsStore: CmsSettingsStore
  ) {
    super(cmsSettingsStore);
  }
}
