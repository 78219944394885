<div class="modal">
    <div class="modal__header">
        <h1>Payment</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>
    <div class="modal__content">
        <form [formGroup]="form">
            <div class="inline-fields">
                <div class="form-control mb-32">
                    <label class="form-control__label">First Name</label>
                    <div class="form-control__block" control-validator>
                        <input formControlName="firstName" type="text" placeholder="John">
                    </div>
                </div>
                <div class="form-control mb-32">
                    <label class="form-control__label">Last Name</label>
                    <div class="form-control__block" control-validator>
                        <input formControlName="lastName" type="text" placeholder="Doe">
                    </div>
                </div>
            </div>
            <div class="inline-fields">
                <div class="form-control inline-fields__grow mb-32">
                    <label class="form-control__label">Credit Card Number</label>
                    <div class="form-control__block">
                        <div id="spreedly-number" class="spreedly-field" style="height: 50px;"></div>
                    </div>
                </div>
                <div class="form-control inline-fields__cvv mb-32">
                    <label for="spreedly-cvv" class="form-control__label">CVV</label>
                    <div class="form-control__block" >
                        <div id="spreedly-cvv" class="spreedly-field" style="height: 50px;"></div>
                    </div>
                </div>
            </div>

            <div class="form-control mb-32">
                <label class="form-control__label">Expiration Date</label>
                <div class="inline-fields">
                    <div class="form-control__block" control-validator>
                        <input
                                inputmode="numeric"
                                formControlName="month"
                                placeholder="MM"
                                type="text"
                                mask="00"
                                pattern="\d*"
                                maxlength="2"
                        />
                    </div>
                    <div class="form-control__block" control-validator>
                        <input
                                inputmode="numeric"
                                formControlName="year"
                                placeholder="YYYY"
                                type="text"
                                mask="0000"
                                pattern="\d*"
                                maxlength="4"
                        />
                    </div>
                </div>
            </div>

            <button [disabled]="!isSpreedlyReady || form.invalid || !isNumberValid || !isCvvValid" (click)="onSubmit()" class="btn btn-large btn-block">
                <span class="mr-18">Pay</span>
            </button>
        </form>
    </div>
</div>
