import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {AccountDetails} from "../../interfaces";

const createInitialState = (): AccountEntity => {
  return {
    detailsLoaded: false,
    detailsIsLoading: false,
    details: null,
  };
}

export interface AccountEntity {
  detailsLoaded: boolean,
  detailsIsLoading: boolean,
  details: AccountDetails | null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'account', resettable: true})
export class AccountStore extends Store <AccountEntity> {
  constructor() {
    super(createInitialState());
  }

  public setDetails(details: AccountDetails): void {
    this.update({
      details: details,
      detailsLoaded: true,
      detailsIsLoading: false,
    })
  }

  public setDetailsLoading(loading: boolean): void {
    this.update({
      detailsIsLoading: loading
    });
  }

  public setDetailsLoaded(loading: boolean): void {
    this.update({
      detailsLoaded: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
