import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LevelsService} from "../../../core/store";
import {Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {PlayerLevel} from "../../../core";
import {PlayerLevelDetailsModalComponent} from "../../modals";
import {ModalService} from "../../services";

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @Input() style: 'default' | 'small' = 'default';
    @Input() clickable: boolean = true;

    private destroy$ = new Subject<boolean>();
    public playerLevel: PlayerLevel | null = null;

    constructor(
        private levelsService: LevelsService,
        private modalService: ModalService,
    ) {
    }

    public get percent(): number {
        if (this.playerLevel === null) return 0;

        return Math.round(this.playerLevel.currentProgress);
    };


    ngOnInit(): void {
        this.levelsService.getPlayerLevel()
            .pipe(
                takeUntil(this.destroy$),
                tap((playerLevel) => {
                    this.playerLevel = playerLevel;
                })
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    openDetails() {
        if (!this.clickable || this.playerLevel === null)
            return;

        const dialogRef = this.modalService.open(PlayerLevelDetailsModalComponent);

        dialogRef.componentInstance.playerLevel = this.playerLevel;
    }
}
