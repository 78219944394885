import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {SeoStateEntity, SeoStore} from "./seo.store";

@Injectable({providedIn: 'root'})
export class SeoQuery extends Query<SeoStateEntity> {
  public bottomContent$ = this.select(item => item.bottomContent);

  constructor(
    private readonly _store: SeoStore
  ) {
    super(_store);
  }
}
