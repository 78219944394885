export class RetryPolicyLoop implements signalR.IRetryPolicy {
    private readonly ReconnectionWaitInSeconds = 5;
    private readonly ErrorIntervalInSeconds = 30;

    private timeoutHandle: any;

    constructor(private onErrorCallback: () => void) {}

    public nextRetryDelayInMilliseconds(retryContext: signalR.RetryContext): number | null {
        console.log(`Retrying attempt: ${retryContext.previousRetryCount + 1}, due to retry reason: ${retryContext.retryReason}`);

        if (retryContext.previousRetryCount === 0 || !this.timeoutHandle) {
            this.startTimeout();
        }

        return this.ReconnectionWaitInSeconds * 1000;
    }

    public clearTimeout() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
    }

    private startTimeout() {
        this.clearTimeout();
        this.timeoutHandle = setTimeout(() => {
            console.error("Connection with notification service is unavailable..");
            this.onErrorCallback();
            this.startTimeout();
        }, this.ErrorIntervalInSeconds * 1000);
    }
}