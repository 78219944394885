import {Injectable} from '@angular/core';
import {GtmEvent} from "../enums";
import {environment} from "../../../environments/environment";

type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

@Injectable({providedIn: 'root'})
export class GtmService {
    constructor() {
    }

    public load() {
        const googleKey = environment.GOOGLE_KEY;

        const script = document.createElement('script');
        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');

        iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleKey}`;
        iframe.height = "0";
        iframe.title = 'GTM';
        iframe.width = "0";
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";

        noscript.appendChild(iframe)

        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleKey}')`

        document.getElementsByTagName('head')[0].appendChild(script);
        document.getElementsByTagName('body')[0].appendChild(noscript);
    }

    public pushEvent(event: GtmEvent): void {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': event
        });
    }
}
