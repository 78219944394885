<div class="modal">
    <div class="modal__header">
        <h1>Time limit reached</h1>
    </div>

    <div class="modal__content">
        <span class="message">You have been logged in for {{limit | hoursPipe}} {{limit !== 1 ? 'hours ' : 'hour '}} today and we will log you out. Welcome back tomorrow!</span>
    </div>

    <div class="modal__actions">
        <button class="btn btn-primary" (click)="goToLogoutPage()">Log out</button>
    </div>
</div>
