import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  GtmService,
  IngeniousService, PopupTriggerType
} from './core';
import {CheckPlatformService, ModalService} from './shared/services';
import {
  CmsSettingsService,
  HubService,
  PaymentsService,
  ResponsibleGamblingService,
  SeoService
} from "./core/store";
import {filter, take, takeUntil} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";
import {PopupsContainerComponent} from "./shared/modals";
import {environment} from "../environments/environment";
import {EVENT_TYPES} from "./shared";
import {EventBus} from "./core/infrastructure";
import { LocalStorageEnums } from './shared/enums';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-popups-container></app-popups-container>
  `
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
    private destroy$ = new Subject<boolean>();

    constructor(
        private readonly eventBus: EventBus,
        private readonly _router: Router,
        private readonly gtmService: GtmService,
        private readonly seoService: SeoService,
        private readonly paymentsService: PaymentsService,
        private readonly renderer: Renderer2,
        private readonly hubService: HubService,
        private readonly checkPlatformService: CheckPlatformService,
        private readonly cmsSettingsService: CmsSettingsService,
        private readonly modalService: ModalService,
        private readonly ing: IngeniousService,
        private readonly responsibleGamblingService: ResponsibleGamblingService
    ) {
        const welcomeFlag = localStorage.getItem(LocalStorageEnums.WelcomeFlag);
        if (welcomeFlag === null) {
            localStorage.setItem(LocalStorageEnums.WelcomeFlag, 'true');
        }
    }

    ngOnInit(): void {
    }

  ngOnDestroy(): void {
    this.destroy$?.next(true);
    this.destroy$?.complete();
  }

  ngAfterContentInit(): void {
    if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
      this._router.events.pipe(
          takeUntil(this.destroy$),
          filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        this.ing.ingeniousHandler.next('ingeniousHandler');

        if (environment.ENVIRONMENT === "production") {
          this.seoService.updateCanonicalUrl()
        }
      });

      this.hubService.connect();
      this.responsibleGamblingService.initActivityCheck();

      this.bindPopupEvents();
    }

    this.cmsSettingsService.getSettings().pipe(
        filter((s) => s !== null),
        take(1)
    ).subscribe((settings) => {
      this.seoService.setMetaTags(settings!.seo);
    });

  }

  ngAfterViewInit() {
    if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
      setTimeout(() => {
        let loader = this.renderer.selectRootElement('#loader');
        this.renderer.removeClass(loader, 'active');
      }, 2000);

      this.gtmService.load();
      this.paymentsService.loadFlykkScript(this.renderer);
      this.paymentsService.loadPayNearMeScript(this.renderer)
    }
  }

  private bindPopupEvents() {
    this.eventBus.OnChange(EVENT_TYPES.PLAYER_SIGN_IN).pipe(
        takeUntil(this.destroy$)
    ).subscribe((msg: any) => {
      const component = this.modalService.open(PopupsContainerComponent);

      component.componentInstance.triggerType = PopupTriggerType.Login;
    });

    this.eventBus.OnChange(EVENT_TYPES.LANDING_EXIT).pipe(
        takeUntil(this.destroy$)
    ).subscribe((msg: any) => {
      const component = this.modalService.open(PopupsContainerComponent);

      component.componentInstance.triggerType = PopupTriggerType.Exit;
    })
  }
}