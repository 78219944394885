import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, finalize, Observable, throwError} from 'rxjs';
import {NavigationService} from '../services';
import {AuthProvider} from '../providers';
import {catchError, filter, shareReplay, switchMap, take} from 'rxjs/operators';
import {AuthFacade} from "../facades";
import {AuthService} from "../store";
import {environment} from "../../../environments/environment";


const TOKEN_HEADER_KEY: string = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private readonly authService: AuthService,
        private readonly authProvider: AuthProvider,
        private readonly authFacade: AuthFacade,
        private readonly navigationService: NavigationService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        if (request.urlWithParams.includes(`/auth/token`)) {
            return next.handle(request)
        }

        if (request.urlWithParams.startsWith(`/cms`)) {
            const token = environment.ENDPOINTS!.CMS.CMS_TOKEN;
            const cmsRequest = this.addTokenHeader(request, token)

            return next.handle(cmsRequest)
        }

        let newRequest: Observable<HttpEvent<Object>>;
        const token = this.authService.tokenFromStorage();

        if (token === null)
            newRequest = next.handle(request);
        else if (this.authService.isExpired(token))
            newRequest = this.performRefreshToken(request, next);
        else
            newRequest = this.handleAuthRequest(request, next);

        return newRequest.pipe(
            shareReplay(1),
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) //
                    return this.performRefreshToken(request, next);

                return throwError(error);
            })
        )
    }

    private performRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        if (this.isRefreshing) {
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(() => this.handleAuthRequest(request, next))
            );
        }

        const refreshToken = this.authService.tokenFromStorage()?.refresh_token!;

        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.authProvider.refresh(refreshToken).pipe(
            switchMap((token: any) => {
                this.authService.setContext(token)

                this.refreshTokenSubject.next(token);

                return this.handleAuthRequest(request, next)
            }),
            catchError((err) => {
                this.authFacade.logout();
                this.authService.dispose();
                this.navigationService.navigateAndRefreshTo('')
                return throwError(err);
            }),
            finalize(() => {
                this.isRefreshing = false;
            })
        )
    }

    private handleAuthRequest(request: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.authService.tokenFromStorage()?.access_token;

        if (!accessToken)
            return next.handle(request);

        return next.handle(this.addTokenHeader(request, accessToken));
    }

    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`).set('Cache-control', 'max-age=0, no-cache, no-store, must-revalidate, private')});
    }
}