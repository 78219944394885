import {Component, Input} from '@angular/core';
import {LevelsService} from "../../../core/store";
import {takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {LoyaltyLevel} from "../../../core";

@Component({
    selector: 'app-level-details',
    templateUrl: './level-details.component.html',
    styleUrls: ['./level-details.component.scss']
})
export class LevelDetailsComponent {
    private destroy$ = new Subject<boolean>();

    @Input() levelNumber: number = 0;

    public level: LoyaltyLevel | null = null;

    constructor(
        public readonly levelsService: LevelsService,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    ngOnInit(): void {
        this.levelsService.getLevelByNumber(this.levelNumber)
            .pipe(
                takeUntil(this.destroy$),
                tap((level) => {
                    this.level = level;
                })
            )
            .subscribe()
    }
}
