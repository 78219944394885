import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {CountriesStore, CountriesStateEntity} from "./countries.store";

@Injectable({providedIn: 'root'})
export class CountriesQuery extends Query<CountriesStateEntity> {
  public countriesLoaded$ = this.select(item => item.countriesLoaded);
  public countriesIsLoading$ = this.select(item => item.countriesIsLoading);
  public countries$ = this.select(item => item.countries);

  constructor(
    private readonly countriesStore: CountriesStore,
  ) {
    super(countriesStore);
  }
}
