import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SettingItem} from "../interfaces/settings";

@Injectable({providedIn: 'root'})
export class SettingsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getSettings(): Observable<SettingItem> {
        const url = `${environment.ENDPOINTS.CMS.URL}/settings`;

        return this.http.get<SettingItem>(url);
    }
}
