import {Directive, TemplateRef, ViewContainerRef} from "@angular/core";
import {environment} from "../../../environments/environment";

@Directive({selector: '[isActivityActive]'})
export class IsActivityActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.ACTIVITY === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isBonusActive]'})
export class IsBonusActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.BONUS === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isGeolocationActive]'})
export class IsGeolocationActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.GEOLOCATION === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isKycActive]'})
export class IsKycActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.KYC === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isNotificationActive]'})
export class IsNotificationActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.NOTIFICATION === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isReportingActive]'})
export class IsReportingActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.REPORTING === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isResponsibleGamblingActive]'})
export class IsResponsibleGamblingActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.RESPONSIBLE_GAMBLING === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}

@Directive({selector: '[isWalletActive]'})
export class IsWalletActive {
    constructor(
        private _vcr: ViewContainerRef,
        private _ref: TemplateRef<any>
    ) {
        if (environment.FEATURES_SETTINGS.WALLET === 'true')
            this._vcr.createEmbeddedView(this._ref);
    }
}
