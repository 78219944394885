import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthFacade, PlayerLimitation} from "../../../core";
import {ResponsibleGamblingService} from "../../../core/store";
import {ModalService} from "../../services";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'app-activity-check-info',
    templateUrl: './activity-check-info.component.html',
    styleUrls: ['./activity-check-info.component.scss']
})
export class ActivityCheckInfoComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();

    public limit: PlayerLimitation | null = null;

    constructor(
        private readonly authFacade: AuthFacade,
        private readonly responsibleGamblingService: ResponsibleGamblingService,
        private readonly modalService: ModalService,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    ngOnInit(): void {
        this.responsibleGamblingService.getLimitations().pipe(
            takeUntil(this.destroy$),
        ).subscribe((limit) => {
            this.limit = limit;
        })
    }

    public goToLogoutPage(): void {
        this.authFacade.logout();
        this.modalService.closeAll()
    }

    public continue(): void {
        this.responsibleGamblingService.resetActivityCheck();
        this.modalService.closeAll()
    }

}
