import {Component} from '@angular/core';
import {ModalService} from "../../services";

@Component({
    selector: 'app-redeem-success-modal',
    templateUrl: './redeem-success-modal.component.html',
    styleUrls: ['./redeem-success-modal.component.scss']
})
export class RedeemSuccessModalComponent {
    constructor(
        private readonly modalService: ModalService
    ) {
    }

    close(): void {
        this.modalService.closeAll();
    }
}
