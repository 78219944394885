import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services';
import { map } from 'rxjs/operators';
import { AuthService } from '../store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Authenticated {
  constructor(
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return true;
        } else {
          if (route?.data?.returnToAfterSignIn)
            localStorage.setItem('urlAfterSignIn', state.url);

          this.navigationService.navigateTo('/auth/sign-in');
          return false;
        }
      })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class CanSignIn {
  constructor(
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          this.navigationService.navigateTo('');
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
