export enum GameType {
  Favorite = 0,
  Last = 1
}

export enum GameCurrency {
  All = 'All',
  SC = 'SC',
  LC = 'LC'
}
