<nav class="header header-mobile">
    <ng-container *ngIf="!isAuthenticated">
        <div class="header-mobile__toggle-icon" (click)="showModalMenu()">
            <i class="u_subject" aria-describedby="Show Side Menu"></i>
        </div>
        <div class="header-mobile__logo" [routerLink]="'/'">
            <img src="../../../../assets/images/LuckySweep-Logo.svg" alt="Caisno Logo" >
        </div>
        <div class="header-mobile__actions">
            <button [routerLink]="'/auth/sign-up'" class="btn btn-small" queryParamsHandling="preserve">
                <span>Sign Up</span>
            </button>
            <button [routerLink]="'/auth/sign-in'" class="btn btn-small" queryParamsHandling="preserve">
                <i class="u_arrow-circle-right"></i>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="isAuthenticated">
        <div class="header-mobile__line-block">
            <div class="header-mobile__toggle-icon" aria-label="Show Side Menu" (click)="showModalMenu()">
                <i class="u_subject" aria-describedby="Show Side Menu"></i>
            </div>
            <div class="header-mobile__logo">
                <a [routerLink]="'/'">
                    <img src="../../../../assets/images/LuckySweep-Logo.svg" alt="Caisno Logo" >
                </a>
            </div>
        </div>
        <div class="balance">
            <app-toggle-coins [horizontalView]="true" [hideTitle]="true"></app-toggle-coins>
        </div>
    </ng-container>
</nav>