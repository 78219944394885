import { 
    AfterViewChecked,
    Directive, 
    ElementRef, 
    Renderer2
} from '@angular/core';
import { ValidationState } from '../enums';

@Directive({ selector: '[control-validator]' })
export class ControlValidatorDirective implements AfterViewChecked {
    constructor(
        private _el: ElementRef,
        private _renderer: Renderer2
    ) { }
    
    public ngAfterViewChecked(): void {
        this.checkIfInputHasError();
    }

    private checkIfInputHasError(): void {
        let input: HTMLElement = this._el.nativeElement.children[0];
        if (
            input.classList.contains(ValidationState.Invalid) && 
            input.classList.contains(ValidationState.Dirty)
        ) {
            this._renderer.addClass(this._el.nativeElement, 'invalid');
        } else if (
            input.classList.contains(ValidationState.Valid)
        ) {
            this._renderer.removeClass(this._el.nativeElement, 'invalid');
        }
    }
}
