import {Inject, Injectable} from "@angular/core";
import {SeoStore} from "./seo.store";
import {SeoItem} from "../../interfaces";
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {SettingsProvider} from "../../providers";
import {filter, take} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {CmsSettingsService} from "../cms-settings";

@Injectable({providedIn: 'root'})
export class SeoService {
    constructor(
        @Inject(DOCUMENT) private dom: any,
        private readonly seoStore: SeoStore,
        private readonly meta: Meta,
        private readonly title: Title,
        private readonly cmsSettingsService: CmsSettingsService,
    ) {
    }

    updateCanonicalUrl() {
        const head = this.dom.getElementsByTagName('head')[0];
        let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;

        if (element === null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }

        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', environment.CANONICAL_DOMAIN + this.dom.location.pathname);
    }

    updateBottomContent(bottomContent: string): void {
        this.seoStore.updateBottomContent(bottomContent);
    }

    setMetaTags(seoItem: SeoItem | null = null) {
        if (!seoItem) {
            this.cmsSettingsService.getSettings().pipe(
                filter((s) => s != null),
                take(1)
            ).subscribe((settings) => {
                if (settings!.seo)
                    this.updateTags(settings!.seo);

                this.clearRobotsTag();
            })
        } else {
            this.updateTags(seoItem);
        }
    }

    updateTags(seoItem: SeoItem) {
        if (seoItem.metaTitle)
            this.title.setTitle(seoItem.metaTitle);

        if (seoItem.metaDescription)
            this.meta.updateTag({name: 'description', content: seoItem.metaDescription});

        if (seoItem.keywords)
            this.meta.updateTag({name: 'keywords', content: seoItem.keywords});

        this.clearRobotsTag();

        if (seoItem.indexed && environment.ENVIRONMENT !== "development")
            this.meta.updateTag({name: 'robots', content: "index, follow"})

        seoItem.metaSocial.forEach((social) => {
            const prefix = social.socialNetwork === "Twitter" ? "twitter" : "og"

            if (social.title)
                this.meta.updateTag({name: `${prefix}:title`, content: social.title});

            if (social.description)
                this.meta.updateTag({name: `${prefix}:description`, content: social.description});

            if (social.image.url)
                this.meta.updateTag({name: `${prefix}:image`, content: social.image.url});
        })

        if (seoItem?.footerInfo)
            this.updateBottomContent(seoItem.footerInfo);
    }

    clearRobotsTag() {
        this.meta.updateTag({name: 'robots', content: "noindex, nofollow"})
    }
}
