<div class="modal">
  <header class="modal__header">
    <h1>Purchase Coins</h1>
    <i (click)="closeAll()" aria-describedby="Close" class="u_times-circle"></i>
  </header>

  <div class="modal__content">
    <app-payment-method
      *ngIf="coinPackage && !isSummary"
      (onBackButtonPressed)="close()"
      (onCancelPressed)="closeAll()"
      (onPaymentComplete)="openSummary($event)"
      [coin]="coinPackage">
    </app-payment-method>
    <app-payment-summary
      *ngIf="coinPackage && isSummary"
      [coin]="coinPackage"
      [data]="summaryData"
      (onClose)="closeAll()">
    </app-payment-summary>
  </div>
</div>