import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CoinPackageOffer, NavigationService} from "../../../core";
import {ModalService} from "../../services";

@Component({
    selector: 'app-claim-modal',
    templateUrl: './claim-modal.component.html',
    styleUrls: ['./claim-modal.component.scss']
})
export class ClaimModalComponent implements OnInit {
    public coinPackage!: CoinPackageOffer;

    constructor(
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly navigationService: NavigationService
    ) {
    }

    ngOnInit(): void {
    }

    public close(): void {
        this.modalService.closeAll();
        if (this.router.url.startsWith('/coinstore')) {
            this.navigationService.navigateTo('/games');
        }
    }

    public play(): void {
        this.close();
        this.router.navigate(['']);
    }

}