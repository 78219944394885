<ng-container *ngIf="popups.length > 0">
    <div class="modal">
        <div class="modal__header">
            <h1>{{ currentPopup.Title }}</h1>
        </div>

        <div class="modal__content">
            <div class="html-data" [innerHTML]="currentPopup.Content | safe: 'html'"></div>
        </div>

        <div class="modal__actions">
            <div class="button-container">
                <button class="btn btn-icon" (click)="previousPopup()" *ngIf="currentIndex !== 0">
                    <i class="icon u_arrow-left"></i>
                </button>
            </div>
            <div class="button-container">
                <button *ngIf="currentPopup.ActionText" class="btn btn-large" (click)="clickAction(currentPopup)">
                    {{ currentPopup.ActionText }}
                </button>
                <button *ngIf="currentPopup.CloseText" class="btn btn-large btn-outlined" (click)="closeAction()">
                    {{ currentPopup.CloseText }}
                </button>
            </div>
            <div class="button-container">
                <button class="btn btn-icon" (click)="nextPopup()" *ngIf="currentIndex !== popups.length - 1">
                    <i class="icon u_arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</ng-container>