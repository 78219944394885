import {Injectable} from "@angular/core";
import {UserInfoService} from "../store";
import {KycStatus, PlayerStatus} from "../enums";
import {NavigationService} from "./navigation.service";
import {RiskStatus} from "../interfaces";
import { MatDialog } from "@angular/material/dialog";

@Injectable({providedIn: 'root'})
export class KycService {
    constructor(
        private userInfoService: UserInfoService,
        private readonly navigationService: NavigationService,
        private dialogRef: MatDialog,
    ) {
    }

    public checkRedeem(): Promise<void> {
        const restrictedStatuses = [
            KycStatus.Unverified,
            KycStatus.InProgress,
            KycStatus.ManualReview,
            KycStatus.Failed,
            KycStatus.Required,
        ]

        return new Promise<void>((resolve) => {
            this.userInfoService.getUser().subscribe((user) => {
                if (!user) return;

                if (restrictedStatuses.includes(user.kycStatus) || user.riskStatus === RiskStatus.Restricted) {
                    this.dialogRef.closeAll();
                    this.navigationService.navigateTo('/kyc/error');
                } else {
                    resolve();
                }
            }).unsubscribe()
        });
    }

    public checkPurchase(): Promise<void> {
        const restrictedStatuses = [
            KycStatus.Failed
        ];

        return new Promise<void>((resolve) => {
            this.userInfoService.getUser().subscribe((user) => {
                if (!user) return;

                if (restrictedStatuses.includes(user.kycStatus) || user.status === PlayerStatus.Restricted || user.riskStatus === RiskStatus.Restricted) {
                    this.dialogRef.closeAll();
                    this.navigationService.navigateTo('/kyc/error');
                } else {
                    resolve();
                }
            }).unsubscribe()
        });
    }
}
