import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'casinoIcon'
})

export class CasinoImage implements PipeTransform {
    transform(value: any, url: string): string {
        const id = this.getIdentifierWithoutSeparator(value);
        return `${url}/${id}.png`;
    }

    private getIdentifierWithoutSeparator(identifier: string): string {
        return identifier.split(':')[1];;
    }
}