import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
    BankAccountType,
    BankAccountTypeLabel,
    BankDetails,
    EnumHelper,
    NotificationService,
    UserInfo
} from "../../../../core";
import {BankDetailsQuery, BankDetailsService} from "../../../../core/store/bank-details";
import {UserInfoQuery} from "../../../../core/store";

@Component({
    selector: 'app-bank-details-form',
    templateUrl: './bank-details-form.component.html',
    styleUrls: ['./bank-details-form.component.scss']
})
export class BankDetailsFormComponent implements OnInit {
    @Output() onSubmit: EventEmitter<boolean> = new EventEmitter();
    @Output() onCancel: EventEmitter<boolean> = new EventEmitter();
    
    public form!: UntypedFormGroup;
    private user!: UserInfo;

    public bankAccountTypeList = EnumHelper.GetObject(BankAccountType, BankAccountTypeLabel);
    public currentFile: any | null = null;

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly bankDetailsService: BankDetailsService,
        private readonly bankDetailsQuery: BankDetailsQuery,
        private readonly notificationService: NotificationService,
        private readonly _user: UserInfoQuery
    ) {
    }

    ngOnInit(): void {
        this.buildForm();

        this.bankDetailsQuery.bankDetails$.subscribe((details) => {
            if (details === null) return;

            this.form.patchValue(details);
            this.currentFile = details.file;
        });

        this._user.user$.subscribe(user => this.user = user);
    }

    public get canSubmit(): boolean {
        return this.form.valid && this.currentFile !== null;
    }

    public onFileChanged(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];

            if(file.size > 10485760){
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Maximum file size is 10MB'
                })
                return;
            }

            this.currentFile = file;
        }
    }

    public removeFile(): void {
        this.currentFile = null;
    }

    public getErrorMessage(formControl: any) {
        if (formControl.hasError('required'))
            return 'This field is required.';
        if (formControl.hasError('pattern'))
            return 'This field is alphanumeric only.';

        return "";
    }

    public submit(): void {
        this.bankDetailsService.setBankDetails({
            accountType: this.form.get('accountType')?.value,
            abaRoutingNumber: this.form.get('abaRoutingNumber')?.value,
            bankName: this.form.get('bankName')?.value,
            bankAccountNumber: this.form.get('bankAccountNumber')?.value,
            accountHolderName: this.form.get('accountHolderName')?.value,
            file: this.currentFile
        } as BankDetails);

        this.onSubmit.emit()
    }

    private buildForm(): void {
        this.form = this._fb.group(
            {
                abaRoutingNumber: [null, [
                    Validators.required
                ]],
                bankName: [null, [
                    Validators.required
                ]],
                bankAccountNumber: [null, [
                    Validators.required,
                ]],
                accountHolderName: [null, [
                    Validators.required,
                ]],
                accountType: [null, [
                    Validators.required,
                ]]
            });
    }

    cancel() {
        this.onCancel.emit()
    }
}
