<div class="card" *ngIf="coin" [class.pinned]="coin.isPinned">
    <div class="card__label"
         *ngIf="coin.labelName && coin.labelColor"
         [ngStyle]="{'background-color': coin.labelColor}"
    >
        <div class="title" [class.dark]="isBackgroundLight">{{ coin.labelName }}</div>
    </div>
    <div class="card__timer" *ngIf="coin.limitedTimeOffer === 1">
        <i class="u_clock"></i>
        <span>{{ countdown }}</span>
    </div>
    <div class="card-content">
        <i
                *ngIf="!hideIcon"
                aria-label="Coin Icon"
                class="card-content__icon card-content__icon-green-coin"
                [class.card-content__icon-star-coin]="isPurchase"
                [class.card-content__icon-green-coin]="isClaim">
        </i>
        <div class="card-content__title">
            <span class="card-content__title-white" *ngIf="coin.goldCoins">
            {{ isClaim ? 'FREE' : '' }} LC {{ coin.goldCoins | number }}
            </span>
            <span class="card-content__title-yellow" *ngIf="coin.sweepCoins">
             + {{ coin.sweepCoins | number }} FREE SC
            </span>
        </div>
    </div>
    <div class="card__footer">
        <div *ngIf="coin.isCryptoOnly" class="card__footer__item">
            <i class="u_bitcoin"></i>
            <span>Only</span>
        </div>
        <div *ngIf="coin.isClaimedOnce" class="card__footer__item">
            Claim once
        </div>
    </div>
    <div class="card__actions">
        <button
                *ngIf="!hideButton && countdown != 'Expired'"
                (click)="select()"
                class="btn btn-large btn-block"
                [class.loading]="inProgress" 
                [disabled]="inProgress || disabledButton"
        >
            <ng-container *ngIf="isClaim">Claim Free Coins</ng-container>
            <ng-container *ngIf="isPurchase">
                <span class="price">
                    <span [class.discount]="hasDiscount">{{ coin.price | currency: 'USD' }}</span>
                    <span *ngIf="hasDiscount">{{ coin.discountPrice | currency: 'USD' }}</span>
                </span>
            </ng-container>
        </button>
    </div>
</div>
