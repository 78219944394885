import { Injectable } from '@angular/core';
import {Address, SignUpModel} from "../interfaces";

@Injectable({providedIn: 'root'})
export class SignupService {
    private _model!: SignUpModel;
    private _address!: Address;
    
    public set address(address: Address) {
        this._address = address;
    }
    public get address(): Address {
        return this._address;
    }

    public set model(options: any) {
        this._model = {...this._model, ...options};
    }

    public get model(): SignUpModel {
        return this._model;
    }
}