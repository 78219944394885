import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
    CoinPackageOffer,
    CryptoType,
    CryptoTypeLabel,
    EnumHelper,
    ForumPayResponse,
    NotificationService,
    OrdersProvider,
    PaymentName,
} from "../../../../core";
import {filter, take, takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {combineLatest, Subject, Subscription} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EVENT_TYPES} from "../../../constants";
import {EventBus} from "../../../../core/infrastructure";
import {PaymentsService} from "../../../../core/store";

@Component({
    selector: 'app-forumpay-payment',
    templateUrl: './forumpay-payment.component.html',
    styleUrls: ['./forumpay-payment.component.scss']
})
export class ForumpayPaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private completeSubscription$: Subscription | null = null;

    @Input()
    public coin!: CoinPackageOffer;

    @Input()
    public showButton: boolean = false;

    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    public form!: UntypedFormGroup;
    public iframeUrl: SafeResourceUrl | null = null;
    public currencies: any[] = [];
    public isFormOpen = false;
    public inProgress = false;
    public paymentInvoice: ForumPayResponse | null = null;
    public refreshSeconds: number = 0;
    public timerHandle: any = null;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly ordersProvider: OrdersProvider,
        private readonly notificationService: NotificationService,
        private readonly eventBus: EventBus,
        private readonly paymentsService: PaymentsService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.buildForm();

        this.form.get("currency")?.valueChanges.pipe(
            takeUntil(this.destroy$),
            filter((currency) => currency !== null),
        ).subscribe((currency) => {
            this.stopRefreshTimer();
            this.updateRates(currency);
            this.startRefreshTimer();
        });

        this.currencies = EnumHelper.GetObject(CryptoType, CryptoTypeLabel).map(c => {
            return {
                name: c.text,
                value: CryptoType[c.value]
            };
        });

        this.eventBus.OnChange<number>(EVENT_TYPES.PAYMENT_STARTED).pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.paymentsService.getProviders())
        ).subscribe(([providerId, providers]) => {
            if (providers.find(p => p.id === providerId)?.name === PaymentName.FORUM_PAY) {
                this.initPayment();
            }
        })
    }

    public get canSubmit(): boolean {
        return !this.inProgress && this.form.valid;

    }

    public initPayment() {
        this.isFormOpen = true;

        this.completeSubscription$ = this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.close();

            this.onComplete.emit({
                status: msg.status
            });
        })
    }

    public getErrorMessage(formControl: any) {
        if (formControl.hasError('required'))
            return 'This field is required.';

        return ""
    }

    public startPayment() {
        if (!this.paymentInvoice) return;

        this.inProgress = true;

        this.stopRefreshTimer();

        this.ordersProvider.createForumPayOrder({
            coinPackageId: this.coin.id,
            paymentId: this.paymentInvoice.payment_id,
            currency: this.paymentInvoice.currency
        }).pipe(
            takeUntil(this.destroy$),
            take(1),
        ).subscribe({
            next: (data) => {
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
            },
            error: (response) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: response?.error?.detail ?? response
                });
            }
        });
    }

    public close() {
        this.stopRefreshTimer();

        this.paymentInvoice = null;
        this.inProgress = false;
        this.isFormOpen = false;
        this.form.reset()

        this.onClose.emit();
        this.completeSubscription$?.unsubscribe();
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

        this.stopRefreshTimer();
    }

    public getInvoiceCurrencyTitle(invoice: ForumPayResponse): string | undefined {
        const currencyType = CryptoType[invoice.currency as keyof typeof CryptoType];
        return CryptoTypeLabel.get(currencyType)
    }

    private buildForm() {
        this.form = this.formBuilder.group({
            currency: [null, [
                Validators.required,
            ]],
        })
    }

    private updateRates(currency: string) {
        this.ordersProvider.getForumPayRates({
            amount: this.coin.discountPrice || this.coin.price,
            currency
        }).pipe(
            takeUntil(this.destroy$),
            take(1),
        ).subscribe({
            next: (data) => {
                this.paymentInvoice = data;
            },
            error: (response) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: response?.error?.detail ?? response
                });
            }
        });
    }

    private startRefreshTimer() {
        this.refreshSeconds = 5;

        this.timerHandle = setInterval(() => {
            this.refreshSeconds--;

            if (this.refreshSeconds === 0) {
                this.updateRates(this.form.get("currency")?.value);
                this.refreshSeconds = 5;
            }
        }, 1000);
    }

    private stopRefreshTimer() {
        if (this.timerHandle) {
            clearInterval(this.timerHandle);
            this.timerHandle = null;
        }
    }
}
