import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {NavigationLink} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class NavigationLInksProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getLinks(type: string): Observable<NavigationLink[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/links/${type}`;
        return this.http.get<any>(url).pipe(
            map(item => item.Links)
        );
    }
}
