import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {PaymentProvider} from "../../interfaces";

const createInitialState = (): PaymentsStateEntity => {
  return {
    providers: [],
    providersLoaded: false,
    providersIsLoading: false,
  };
}

export interface PaymentsStateEntity {
  providers: PaymentProvider[],
  providersLoaded: boolean,
  providersIsLoading: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'providers', resettable: true})
export class PaymentsStore extends Store<PaymentsStateEntity> {
  constructor() {
    super(createInitialState());
  }

  setProviders(providers: PaymentProvider[]) {
    this.update(state => ({
      providers,
      providersLoaded: true,
      providersIsLoading: false,
    }));
  }

  public setLoading(loading: boolean): void {
    this.update({
      providersIsLoading: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
