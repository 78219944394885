import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {LevelsEntity, LevelsStore} from "./levels.store";

@Injectable({providedIn: 'root'})
export class LevelsQuery extends Query<LevelsEntity> {
    public levels$ = this.select(item => item.levels);
    public levelsLoaded$ = this.select(item => item.levelsLoaded);
    public levelsIsLoading$ = this.select(item => item.levelsIsLoading);
    public playerLevel$ = this.select(item => item.playerLevel);
    public playerLevelLoaded$ = this.select(item => item.playerLevelLoaded);
    public playerLevelIsLoading$ = this.select(item => item.playerLevelIsLoading);

    constructor(
        private readonly _store: LevelsStore
    ) {
        super(_store);
    }
}
