import { AbstractControl } from '@angular/forms';
import { getAge, getYear } from '../utils';

export class BirthdayValidators {
  static checkClientAge(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (!control.value) return null;
    if (getAge(control.value) < 18) {
      return { 'notEnoughYears': true };
    } else if (getYear(control.value) < 1900) {
      return { 'notEnoughDigits': true };
    } else {
      return null;
    }
  }
}