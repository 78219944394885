import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {AccountStore, AccountEntity} from "./account.store";

@Injectable({providedIn: 'root'})
export class AccountQuery extends Query<AccountEntity> {
  public details$ = this.select(item => item.details);
  public detailsLoaded$ = this.select(item => item.detailsLoaded);
  public detailsIsLoading$ = this.select(item => item.detailsIsLoading);

  constructor(
    private readonly _store: AccountStore
  ) {
    super(_store);
  }
}
