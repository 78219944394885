import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-old-and-new',
  templateUrl: './old-and-new.component.html',
  styleUrls: ['./old-and-new.component.scss']
})
export class OldAndNewComponent {
  @Input()
  public oldPrice: number = 0;
  @Input()
  public newPrice: number = 0;
}
