import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {PaymentMethod, PaymentProvider} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PaymentsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getActivePaymentMethods(): Observable<PaymentMethod[]> {
        const url = `${environment.ENDPOINTS.API.URL}/payments/methods/active`;

        return this.http.get<PaymentMethod[]>(url);
    }

    public getPaymentProviders(): Observable<PaymentProvider[]> {
        const url = `${environment.ENDPOINTS.API.URL}/payments/providers`;

        return this.http.get<PaymentProvider[]>(url);
    }
}
