import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {CreateRedeemResponse, PNMMethodRequest, Redeem, RedeemRequest, RedemptionCodeResponse} from "../interfaces";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class RedeemProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public redeem(data: Redeem): Observable<CreateRedeemResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/wallets/redeem`;
        return this.http.post<CreateRedeemResponse>(url, data);
    }

    public playerRedeems(): Observable<RedeemRequest[]> {
        const url = `${environment.ENDPOINTS.API.URL}/redeems/player`;
        return this.http.get<RedeemRequest[]>(url);
    }

    public deletePlayerRedeem(id: string): Observable<void> {
        const url = `${environment.ENDPOINTS.API.URL}/redeems/${id}`;
        return this.http.delete<void>(url);
    }

    public redemptionCode(): Observable<RedemptionCodeResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/account/redemption`;
        return this.http.post<RedemptionCodeResponse>(url, []);
    }

    public pnmMethod(data: PNMMethodRequest): Observable<any> {
        const url = `${environment.ENDPOINTS.API.URL}/redeems/pnm-method`;
        return this.http.post<any>(url, data);
    }
}
