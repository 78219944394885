import {Currency} from "../../shared";
import {KycStatus, PlayerStatus} from "../enums";

export interface UserInfo {
    id: number,
    registrationTime: string,
    firstName: string,
    lastName: string,
    birthday: string,
    email: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    ssn?: number | null,
    status: PlayerStatus,
    statusReason: number,
    kycStatus: KycStatus,
    riskStatus: number,
    hasPassword: boolean
    accessType: string
}

export enum RiskStatus {
    None,
    Restricted,
    Suspicious
}

export interface UserBalanceDetails {
    id: string,
    currency: Currency,
    balance: number,
    lockedBalance: number,
    active: boolean,
}

export interface UserBalanceSocketResponse {
    refresh: boolean,
    balance: number,
    balanceDetails: UserBalanceDetails[];
}



