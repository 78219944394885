import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class PasswordValidators {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          if (!control.value) {
            return null;
          }
          const valid = regex.test(control.value);
          return valid ? null : error;
        };
    }

    static passwordMatchValidator(control: AbstractControl): void {
        const password: string = control.get('password')!.value;
        const repeatPassword: string = control.get('repeatPassword')!.value;
        if (password !== repeatPassword) {
          control.get('repeatPassword')!.setErrors({ NoPassswordMatch: true });
        }
    }
}