import {Component} from '@angular/core';
import {ModalService} from "../../services";

@Component({
  selector: 'app-daily-spin-modal',
  templateUrl: './daily-spin-modal.component.html',
  styleUrls: ['./daily-spin-modal.component.scss']
})
export class DailySpinModalComponent {
  constructor(
      private readonly modalService: ModalService
  ) {
  }

  public close(): void {
    this.modalService.closeAll();
  }
}
