import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {PageRewardItem} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class RewardsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getAll(): Observable<PageRewardItem[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/rewards`;

        return this.http.get<PageRewardItem[]>(url);
    }
}
