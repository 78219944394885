import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {SettingItem} from "../../interfaces/settings";

const createInitialState = (): CmsSettingsStateEntity => {
  return {
    settings: null,
    settingsLoaded: false,
    settingsIsLoading: false,
  };
}

export interface CmsSettingsStateEntity {
  settings: SettingItem | null,
  settingsLoaded: boolean,
  settingsIsLoading: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'cms-settings', resettable: true})
export class CmsSettingsStore extends Store <CmsSettingsStateEntity> {
  constructor() {
    super(createInitialState());
  }

  public updateSettings(settings: SettingItem): void {
    this.update({
      settings: settings,
      settingsLoaded: true,
      settingsIsLoading: false
    })
  }

  public setLoading(loading: boolean): void {
    this.update({
      settingsIsLoading: loading
    });
  }
}
