import { Injectable } from '@angular/core';
import { Notification } from '../interfaces';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly toastrService: ToastrService) {}

  public showNotification(
    notification: Notification,
    disableTimeOut = false
  ): void {
    const activeToast = this.toastrService.show(
      notification.message,
      notification.title,
      {
        timeOut: 3000,
        disableTimeOut: disableTimeOut,
        positionClass: 'toast-top-center',
      },
      `toast-${notification.type}`
    );

    if (notification.action !== null) {
      activeToast.onAction.subscribe(() => {
        notification.action!();
      });
    }
  }
}
