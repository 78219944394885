<div class="actions">
    <ng-container *ngIf="showButton">
        <button class="btn btn-extra-large btn-blue btn-block" (click)="initPayment()" [disabled]="inProgress">
            <span>PayNearMe</span>
        </button>
    </ng-container>
</div>
<div class="status" *ngIf="inProgress">
    <img class="status-img" src="../../../../../assets/images/loading.svg" alt="progress">
    <label class="status-title">Payment in progress</label>
    <label class="status-info">Please don’t close this window.</label>
</div>