<section class="sign-in">
    <form *ngIf="form" [formGroup]="form">
        <h1 class="sign-in__title">Login</h1>
        <div class="form-control mb-16">
            <ng-container *ngLet="form.get('email') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                >
                    <mat-label>Email address</mat-label>
                    <input placeholder="Email address" email maxlength="255" type="email" formControlName="email" matInput>
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="form-control mb-16">
            <mat-form-field appearance="outline" class="material-block material-block__input">
                <mat-label>Password</mat-label>
                <input placeholder="Password" maxlength="255" [type]="isPasswordVisible ? 'text' : 'password'"
                       formControlName="password" matInput>
                <mat-icon class="icon-gray" (click)="isPasswordVisible = !isPasswordVisible"
                          matSuffix>{{isPasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <div>
                <span class="reset-field" (click)="resetPassword()">
                    Forgot password?
                </span>
            </div>
        </div>

        <button [disabled]="form.invalid || isLoading" [class.loading]="isLoading" class="btn btn-large btn-sign-in" (click)="signIn()">
            Login
            <i class="u_arrow-circle-right ml-14" aria-describedby="Arrow right"></i>
        </button>
        <div class="divider">or</div>
        <button class="btn btn-block btn-extra-large btn-blue" (click)="signInByFacebook()">
            <i class="u_facebook" aria-describedby="Facebook"></i>
            Login with Facebook
        </button>
        <div class="footer-message">
            <span>Don't have an account? </span>
            <a (click)="signUp()" aria-label="Sign-up">
                Sign Up
            </a>
        </div>
    </form>
</section>