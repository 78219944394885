<div class="details-modal">
    <app-common-header [showBackButton]="false" (onClose)="close()"></app-common-header>
    <div class="auth-container">
        <div class="details-container">
            <div class="details-container__body account-details">
                <div class="container">
                    <div class="form-title">
                        Please fill out the last part of your Sweeptastic account.
                    </div>
                    <app-user-details-form (details)="setUserDetails($event)"></app-user-details-form>
                    <button [disabled]="isLoading || userDetails === null" (click)="onSubmit()" class="btn btn-large btn-block">
                        <span class="mr-18">Save</span>
                        <i class="u_save" aria-describedby="Save"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
