import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";

const createInitialState = (): NavigationBarEntity => {
  return {
    isMinimized: false,
  };
}

export interface NavigationBarEntity {
  isMinimized: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'navigationBar', resettable: true})
export class NavigationBarStore extends Store <NavigationBarEntity> {
  constructor() {
    super(createInitialState());
  }

  public updateState(state: boolean): void {
    this.update({
      isMinimized: state
    })
  }
}
