import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {ResponsibleGamblingStore, RgStateEntry} from "./rg.store";

@Injectable({providedIn: 'root'})
export class ResponsibleGamblingQuery extends Query<RgStateEntry> {
    public allState$ = this.select();
    public timerStarted$ = this.select(item => item.timers)
    public limitation$ = this.select(item => item.limitation);
    public limitationLoaded$ = this.select(item => item.limitationLoaded);
    public limitationIsLoading$ = this.select(item => item.limitationIsLoading);

    constructor(
        private readonly _store: ResponsibleGamblingStore
    ) {
        super(_store);
    }

}
