import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ModalService} from "../../services";
import {takeUntil, tap} from "rxjs/operators";
import {PlayerCardDetails, PlayersProvider} from "../../../core";

@Component({
    selector: 'app-player-details-modal',
    templateUrl: './player-details-modal.component.html',
    styleUrls: ['./player-details-modal.component.scss']
})
export class PlayerDetailsModalComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public cardDetails: PlayerCardDetails | null = null;
    public playerId: number | null = null;

    constructor(
        private readonly modalService: ModalService,
        private readonly playersProvider: PlayersProvider,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    ngOnInit(): void {
        this.getDetails();
    }

    public close(): void {
        this.modalService.closeAll();
    }

    private getDetails() {
        if (this.playerId === null) return;

        this.playersProvider.getCardDetails(this.playerId).pipe(
            takeUntil(this.destroy$)
        ).subscribe((cardDetails) => {
            this.cardDetails = cardDetails;
        })
    }
}
