export enum AdjustmentCategories {
    None = 0,
    Debit = 1,
    Credit = 2
}

export enum PlayerStatus
{
    None = 0,
    Pending = 1,
    Active = 2,
    Disabled = 3,
    Restricted = 4,
    Closed = 5
}
