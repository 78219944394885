import {Injectable} from "@angular/core";
import {NavigationLinksQuery} from "./navigation-links.query";
import {NavigationLinksStore} from "./navigation-links.store";
import {Observable, of} from "rxjs";
import {map, switchMap, take, tap} from "rxjs/operators";
import {NavigationLInksProvider} from "../../providers";
import {NavigationLink} from "../../interfaces";

@Injectable({providedIn: 'root'})
export class NavigationLinksService {
    constructor(
        private readonly navigationLinksStore: NavigationLinksStore,
        private readonly navigationLinksQuery: NavigationLinksQuery,
        private readonly navigationLInksProvider: NavigationLInksProvider,
    ) {
    }

    public getLinks(type: string): Observable<NavigationLink[]> {
        return this.navigationLinksQuery.items$.pipe(
            map(items => items.get(type)),
            switchMap(links => links === undefined ? this.loadLinks(type) : of(links))
        )
    }

    public loadLinks(type: string): Observable<NavigationLink[]> {
        return this.navigationLInksProvider.getLinks(type).pipe(
            take(1),
            tap(links => {
                this.navigationLinksStore.loadItems(type, links)
            })
        )
    }

    public updateCurrentPath(path: string): void {
        this.navigationLinksStore.updateCurrentPath(path);
    }
}
