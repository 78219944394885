import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CasinoPage} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PagesProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getPage(slug: string): Observable<CasinoPage> {
        const url = `${environment.ENDPOINTS.CMS.URL}/pages/${slug}`;

        return this.http.get<CasinoPage>(url);
    }
}
