import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(public matDialog: MatDialog) {}

  public open(
    component: ComponentType<any>,
    config: MatDialogConfig | undefined = undefined
  ): MatDialogRef<any> {
    return this.matDialog.open(component, config);
  }

  public closeAll(): void {
    this.matDialog.closeAll();
  }
}