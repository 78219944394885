import {Component} from '@angular/core';
import {BankDetailsService} from "../../../core/store/bank-details";
import {ModalService} from "../../services";

@Component({
    selector: 'app-bank-details-modal',
    templateUrl: './bank-details-modal.component.html',
    styleUrls: ['./bank-details-modal.component.scss']
})
export class BankDetailsModalComponent {
    public currentStep: number = 0;
    public steps = [
        "Add bank details",
        "Confirm bank details",
        "Success"
    ]

    constructor(
        private readonly bankDetailsService: BankDetailsService,
        private readonly modalService: ModalService,
    ) {
    }

    public get pageTitle(): string {
        return this.steps[this.currentStep];
    }

    close(): void {
        this.bankDetailsService.clear();
        this.modalService.closeAll();
    }

    goBack() {
        this.currentStep--;
    }

    goNext() {
        this.currentStep++;
    }
}
