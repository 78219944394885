import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, take} from "rxjs/operators";
import {FooterBlocks, FooterHtmlBlock, FooterLinkBlock} from "../../../core";
import {Subject} from "rxjs";
import {castTo} from "../../utils";
import {CmsSettingsService, SeoQuery} from "../../../core/store";

@Component({
    selector: 'app-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public blocks: (FooterLinkBlock | FooterHtmlBlock)[] = [];

    public bottomContent: string = "";
    public isContentHidden: boolean = true;
    public showBottomContent: boolean = false;
    public FooterBlocksTypes = FooterBlocks;

    public $html = castTo<FooterHtmlBlock>();
    public $links = castTo<FooterLinkBlock>();

    constructor(
        private cmsSettingsService: CmsSettingsService,
        private seoQuery: SeoQuery,
    ) {
    }

    ngOnInit(): void {
        this.cmsSettingsService.getSettings().pipe(
            filter((s) => s !== null),
            take(1)
        ).subscribe((settings) => {
            this.blocks = settings!.Footer
        });

        this.seoQuery.bottomContent$.subscribe((content) => {
            this.bottomContent = content;
            this.showBottomContent = !!content;
        })
    }

    public toggleHidden() {
        this.isContentHidden = !this.isContentHidden;
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
