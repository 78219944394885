import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {LobbyGame} from "../../../core/interfaces/lobby";
import {GamesProvider, NavigationService} from "../../../core";
import {GamesService, SearchQuery} from "../../../core/store";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil, tap} from "rxjs/operators";
import {ModalService} from "../../services";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchContainer', { static: true }) searchContainer!: ElementRef;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  private destroy$ = new Subject<boolean>();
  public recentGames: LobbyGame[] = [];
  private _notifier = new Subject();
  public games: LobbyGame[] = [];
  public showRecent: boolean = true;

  constructor(
      private _nav: NavigationService,
      private readonly gamesProvider: GamesProvider,
      private readonly _query: SearchQuery,
      private readonly _gamesService: GamesService,
      private readonly modalService: ModalService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this._query.criteria$.pipe(
        takeUntil(this._notifier),
        debounceTime(200),
        distinctUntilChanged()
    ).subscribe(async (criteria: string) => {
      if (!criteria || criteria.length < 2) {
        this.showRecent = true;
        this.games = [];
        return;
      }

      this.showRecent = false;
      this.games = await this.gamesProvider.getGamesByCriteria(criteria.toLowerCase()).toPromise() as LobbyGame[];
    });

    // if (window.visualViewport) {
    //   window.visualViewport.onresize = () => {
    //     this.searchContainer.nativeElement.style.height = window?.visualViewport?.height + 'px';
    //
    //     // const appRoot = document.querySelector('app-root') as HTMLElement;
    //     // appRoot.style.display = appRoot.style.display == 'none' ? '' : 'none';
    //
    //     window.scrollTo(0, 0);
    //   }
    // }

    this._gamesService.getRecentGames().pipe(
        takeUntil(this.destroy$),
        tap(async (recentGames) => {
          this.recentGames = recentGames.slice(0,3);
        })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._notifier?.next(null);
    this._notifier?.complete();
  }

  close() {
    this.modalService.closeAll();
  }
}
