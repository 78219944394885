import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {NavigationBarStore, NavigationBarEntity} from "./navigation-bar.store";

@Injectable({providedIn: 'root'})
export class NavigationBarQuery extends Query<NavigationBarEntity> {
  public isMinimized$ = this.select(item => item.isMinimized);

  constructor(
    private readonly _store: NavigationBarStore
  ) {
    super(_store);
  }
}
