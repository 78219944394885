import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {LobbyCategory, LobbyCategoryGamesResponse, LobbyGame} from "../interfaces/lobby";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CategoriesProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getCategory(categorySlug: string): Observable<LobbyCategory> {
        const url = `${environment.ENDPOINTS.CMS.URL}/categories/${categorySlug}`;

        return this.http.get<LobbyCategory>(url);
    }

    public getCategoryGames(categorySlug: string, take: number, skip: number): Observable<LobbyCategoryGamesResponse> {
        const url = `${environment.ENDPOINTS.CMS.URL}/categories/${categorySlug}/games?take=${take}&skip=${skip}`;

        return this.http.get<[LobbyGame[], number]>(url).pipe(
            map((data) => {
                return {
                    items: data[0],
                    totalCount: data[1]
                } as LobbyCategoryGamesResponse
            })
        );
    }
}
