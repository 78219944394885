<div *ngIf="playerLevel"
     class="progress-bar conic-{{percent}} {{style}}"
     [class.clickable]="clickable"
     (click)="openDetails()"
>
    <div class="progress-bar__content">
        <div class="level">
            {{playerLevel.level}}
        </div>
        <div class="title">
            Level
        </div>
    </div>
</div>
