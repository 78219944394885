import {Injectable} from "@angular/core";
import {BankDetailsStore} from "./bank-details.store";
import {BankDetails} from "../../interfaces";

@Injectable({providedIn: 'root'})
export class BankDetailsService {
    constructor(
        private readonly bankDetailsStore: BankDetailsStore,
    ) {
    }

    public setBankDetails(bankDetails: BankDetails): void {
        this.bankDetailsStore.setBankDetails(bankDetails);
    }

    public clear(): void {
        this.bankDetailsStore.clear();
    }
}
