<div class="modal">
    <header class="modal__header">
        <h1>Cancel Redeem Request</h1>
        <i (click)="closeAll()" aria-describedby="Close" class="u_times-circle"></i>
    </header>

    <div class="modal__content">
        <ng-container *ngIf="isSuccess">
            <img class="status-img" src="../../../../assets/images/LuckySweep-Logo.svg" alt="sweep-logo">
            <img class="status-img" src="../../../../assets/images/coin-success.svg" alt="success">

            <div class="redeem-info">
                <div class="redeem-info__title">Cancellation successful</div>
                <div class="redeem-info__subtitle">{{request?.amount | number : '1.2-2'}} SC has been credited to your account.</div>
            </div>

            <button class="btn btn-large" (click)="closeAll()" aria-label="Back to games">
                <span>Close</span>
            </button>
        </ng-container>

        <ng-container *ngIf="!isSuccess">
            <img class="status-img" src="../../../../assets/images/LuckySweep-Logo.svg" alt="sweep-logo">
            <img class="status-img" src="../../../../assets/images/close.svg" alt="progress">

            <div class="redeem-info">
                <div class="redeem-info__title">Cancellation failed</div>
                <div class="redeem-info__subtitle error">Your Prize Redemption Request could not be cancelled.</div>
            </div>

            <button class="btn btn-large" (click)="close()" aria-label="Back to games">
                <span>Back to Games</span>
            </button>
        </ng-container>
    </div>
</div>
