<div class="modal">
    <div class="modal__header">
        <i
                *ngIf="currentStep > 0 && currentStep !== 2"
                (click)="goBack()"
                aria-label="Back"
                class="u_arrow-circle-left">
        </i>
        <h1>{{pageTitle}}</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <app-bank-details-form
                *ngIf="currentStep === 0"
                (onSubmit)="goNext()"
                (onCancel)="close()"
        >
        </app-bank-details-form>
        <app-bank-details-confirmation
                *ngIf="currentStep === 1"
                (onSubmit)="goNext()"
                (onBack)="goBack()"
        >
        </app-bank-details-confirmation>
        <app-bank-details-success
                *ngIf="currentStep === 2"
                (onClose)="close()"
        >
        </app-bank-details-success>
    </div>
</div>
