import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";

const createInitialState = (): SeoStateEntity => {
  return {
    bottomContent: "",
  };
}

export interface SeoStateEntity {
  bottomContent: string,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'search', resettable: true})
export class SeoStore extends Store <SeoStateEntity> {
  constructor() {
    super(createInitialState());
  }

  public updateBottomContent(bottomContent: string): void {
    this.update({
      bottomContent: bottomContent
    })
  }
}
