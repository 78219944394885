import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "numberFormat",
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: string): string {
        const numberValue = Number(value);

        if (numberValue >= 1000000) {
            return (numberValue / 1000000).toFixed(2) + "M";
        }

        if (numberValue >= 1000) {
            return (numberValue / 1000).toFixed(0) + "K";
        }

        return value.toString();
    }
}
