import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {ITokenObject} from "../../interfaces";

const createInitialState = (): AuthStateEntity => {
  return {
    token: null,
    tokenLoaded: false,
    tokenIsLoading: false,
  };
}

export interface AuthStateEntity {
  token: ITokenObject | null,
  tokenLoaded: boolean,
  tokenIsLoading: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'search', resettable: true})
export class AuthStore extends Store <AuthStateEntity> {
  constructor() {
    super(createInitialState());
  }

  public setToken(token: ITokenObject): void {
    this.update({
      token: token,
      tokenLoaded: true,
      tokenIsLoading: false,
    })
  }

  public setTokenLoading(loading: boolean): void {
    this.update({
      tokenIsLoading: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
