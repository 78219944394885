import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {LoyaltyLevel, PlayerLevel} from "../../interfaces";

const createInitialState = (): LevelsEntity => {
  return {
    levelsLoaded: false,
    levelsIsLoading: false,
    levels: [],
    playerLevelLoaded: false,
    playerLevelIsLoading: false,
    playerLevel: null,
  };
}

export interface LevelsEntity {
  levelsLoaded: boolean,
  levelsIsLoading: boolean,
  levels: LoyaltyLevel[],
  playerLevelLoaded: boolean,
  playerLevelIsLoading: boolean,
  playerLevel: PlayerLevel | null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'account', resettable: true})
export class LevelsStore extends Store <LevelsEntity> {
  constructor() {
    super(createInitialState());
  }

  public setLevels(levels: LoyaltyLevel[]): void {
    this.update({
      levels: levels,
      levelsLoaded: true,
      levelsIsLoading: false,
    })
  }

  public setLevelsLoading(loading: boolean): void {
    this.update({
      levelsIsLoading: loading
    });
  }

  public setPlayerLevel(playerLevel: PlayerLevel): void {
    this.update({
      playerLevel: playerLevel,
      playerLevelLoaded: true,
      playerLevelIsLoading: false,
    })
  }

  public setPlayerLevelLoading(loading: boolean): void {
    this.update({
      playerLevelIsLoading: loading
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}
