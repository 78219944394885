import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {PopupItem} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PopupTriggerType} from "../enums";

@Injectable({providedIn: 'root'})
export class PopupsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getByTrigger(trigger: PopupTriggerType): Observable<PopupItem[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/popups/${trigger}`;

        return this.http.get<PopupItem[]>(url);
    }
}
