import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CategoriesProvider} from "../../../core";
import {takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {LobbyCategory} from "../../../core/interfaces/lobby";

@Component({
    selector: 'app-game-strip',
    templateUrl: './game-strip.component.html',
    styleUrls: ['./game-strip.component.scss']
})
export class GameStripComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Input() categorySlug!: string;
    @Input() vertical: boolean = false;

    public category!: LobbyCategory;

    constructor(
        private readonly categoriesProvider: CategoriesProvider
    ) {
    }

    ngOnInit(): void {
        this.categoriesProvider.getCategory(this.categorySlug).pipe(
            takeUntil(this.destroy$),
            tap((category) => {
                this.category = category;
            })
        ).subscribe()
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
