import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-bank-details-success',
  templateUrl: './bank-details-success.component.html',
  styleUrls: ['./bank-details-success.component.scss']
})
export class BankDetailsSuccessComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  close() {
    this.onClose.emit()
  }
}
