import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";

const createInitialState = (): SearchStateEntity => {
  return {
    criteria: "",
    isOpen: false
  };
}

export interface SearchStateEntity {
  criteria: string,
  isOpen: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'search', resettable: true})
export class SearchStore extends Store <SearchStateEntity> {
  constructor() {
    super(createInitialState());
  }

  public updateCriteria(criteria: string): void {
    this.update({
      criteria: criteria
    })
  }

  public updateStatus(isOpen: boolean): void {
    this.update({
      isOpen: isOpen
    })
  }
}
