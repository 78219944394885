export enum PaymentType {
    None = 0,
    Card = 1,
    IBT = 2,
    Crypto = 3,
    GooglePay = 4,
    ApplePay = 5
}

export const PaymentName = {
    FLYKK: 'Flykk',
    NUVEI: 'Nuvei',
    ISX: 'ISX',
    FORUM_PAY: 'ForumPay',
    CHECKOUT: 'Checkout',
    PAYNEARME: 'PNM',
} as const;

export const PaymentTypeLabel = new Map<number, string>(
    [
        [PaymentType.Card, 'Card'],
        [PaymentType.IBT, 'IBT'],
        [PaymentType.Crypto, 'Crypto'],
        [PaymentType.GooglePay, 'GooglePay'],
        [PaymentType.ApplePay, 'ApplePay'],
    ])