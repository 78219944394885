<button
        type="button"
        [class]="type"
        [class.disabled]="disabled"
        [class.full-width]="fullWidthMode"
        (click)="onClick()">
    <i *ngIf="icon && iconPosition === 'before'" [class]="icon"></i>
    <ng-content></ng-content>
    <span *ngIf="text">{{text}}</span>
    <i *ngIf="icon && iconPosition === 'after'" [class]="icon"></i>
</button>
