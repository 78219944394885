import { Pipe, PipeTransform } from '@angular/core';
import { TransactionCategory, TransactionStatus } from 'src/app/core/enums/transaction';

@Pipe({
    name: 'transactionStatus'
})

export class TransactionStatusPipe implements PipeTransform {
    transform(value: any): string {
        return TransactionStatus[value];
    }
}

@Pipe({
    name: 'transactionIcon'
})

export class TransactionIconPipe implements PipeTransform {
    transform(value: any): string {
        return checkIfBackofficePresentInType(value.toLowerCase());
    }
}

@Pipe({
    name: 'transactionCategory'
})

export class TransactionCategoryPipe implements PipeTransform {
    transform(value: any): string {
        return TransactionCategory[value];
    }
}

function checkIfBackofficePresentInType(type: string): string {
    if (type.includes('backoffice')) {
        return type.replace('backoffice', '');
    } else {
        return type;
    }

}

@Pipe({
    name: 'transactionSource'
})

export class TransactionSourcePipe implements PipeTransform {
    transform(value: any): string {
        if (!value) return '';
        if (value.transactionSourceId === 1 && (value.transactionTypeId === 1 || value.transactionTypeId === 2)) {
            return 'System';
        } else {
            return value.transactionSource || '';
        }
    }
}