import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CoinPackageOffer} from "../../../../../core";
import {TransactionStatus, TransactionStatusLabel} from "../../../../../core/enums/transaction";
import {EVENT_TYPES} from "../../../../constants";
import {takeUntil} from "rxjs/operators";
import {EventBus} from "../../../../../core/infrastructure";
import {Subject} from "rxjs";

@Component({
    selector: 'app-payment-summary',
    templateUrl: './payment-summary.component.html',
    styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Output()
    public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public coin!: CoinPackageOffer;

    @Input()
    public data!: any;

    constructor(
        private readonly eventBus: EventBus
    ) {
    }

    public get isSuccess() {
        return this.data.status === TransactionStatus.Succeeded;
    }

    public get isFail() {
        return this.data.status === TransactionStatus.Failed || this.data.status === TransactionStatus.Canceled
    }

    public get isPending() {
        return this.data.status === TransactionStatus.Pending || this.data.status === TransactionStatus.New || this.data.status === TransactionStatus.None
    }

    public get statusText() {
        return TransactionStatusLabel.get(this.data.status) ?? "pending"
    }

    ngOnInit(): void {
        this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.data.status = msg.status;
        })
    }

    public close(): void {
        this.onClose.emit(false);
    }

    public get coinTitle(): string {
        let title = "";

        if (this.coin.goldCoins) {
            title += `LC ${this.coin.goldCoins}`
        }

        if (this.coin.sweepCoins) {
            title += ` + FREE SC ${this.coin.sweepCoins}`
        }

        return title;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
