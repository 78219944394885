import {Injectable} from "@angular/core";
import {LevelsStore} from "./levels.store";
import {Observable, switchMap} from "rxjs";
import {LoyaltyLevel, PlayerLevel} from "../../interfaces";
import {filter, map, tap, withLatestFrom} from "rxjs/operators";
import {LevelsQuery} from "./levels.query";
import {LevelsProvider} from "../../providers";
import {UserInfoService} from "../user";

@Injectable({providedIn: 'root'})
export class LevelsService {

    constructor(
        private readonly levelsStore: LevelsStore,
        private readonly levelsQuery: LevelsQuery,
        private readonly levelsProvider: LevelsProvider,
        private readonly userInfoService: UserInfoService,
    ) {
    }

    public clear(): void {
        this.levelsStore.clear();
    }

    public getLevelByNumber(levelNumber: number): Observable<LoyaltyLevel | null> {
        return this.getLevels().pipe(
            map((levels) => {
                const currentLevel = levels.filter(l => +l.Level === levelNumber);
                return currentLevel.length > 0 ? currentLevel[0] : null;
            })
        )
    }

    public getLevels(): Observable<LoyaltyLevel[]> {
        return this.levelsQuery.levels$.pipe(
            withLatestFrom(
                this.levelsQuery.levelsLoaded$,
                this.levelsQuery.levelsIsLoading$
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadLevels();
                }
            }),
            map(([levels, loaded]) => {
                return loaded ? levels : [];
            })
        )
    }

    public loadLevels(): void {
        this.levelsStore.setLevelsLoading(true);

        this.levelsProvider.getLevels().subscribe((levels) => {
            this.levelsStore.setLevels(levels);
        })
    }

    public getPlayerLevel(): Observable<PlayerLevel | null> {
        return this.levelsQuery.playerLevel$.pipe(
            withLatestFrom(
                this.levelsQuery.playerLevelLoaded$,
                this.levelsQuery.playerLevelIsLoading$
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadPlayerLevel();
                }
            }),
            map(([level, loaded]) => {
                return loaded ? level : null;
            })
        )
    }

    public loadPlayerLevel(): void {
        this.levelsStore.setPlayerLevelLoading(true);

        this.userInfoService.getUser().pipe(
            filter((user) => user !== null && user !== undefined),
            switchMap((user) => {
                return this.levelsProvider.getPlayerLevel(user!.id)
            })
        ).subscribe((level) => {
            this.levelsStore.setPlayerLevel(level);
        });
    }

    public updatePlayerLevel(level: PlayerLevel): void {
        this.levelsStore.setPlayerLevel(level);
    }
}
