import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  ActivityCheckRequest,
  GamblingLimit,
  PlayerCardDetails,
  PlayerLimitation, ReferralCode,
  SelfExclusionRequest,
  TimeLimitRequest
} from '../interfaces';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PlayersProvider {

  constructor(
      private readonly http: HttpClient
  ) {
  }

  public getPlayerLimitations(): Observable<PlayerLimitation> {
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling`;
    return this.http.get<PlayerLimitation>(url);
  }

  public removePlayerLimit(limitId: string): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/limits/${limitId}`;
    return this.http.delete(url);
  }

  public editPlayerLimit(limitId: string, limit: GamblingLimit, amount: number): Observable<any> {
    const limitEntity = limit;
    limit.amount = amount
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/limits/${limitId}`;
    return this.http.put(url, limitEntity);
  }

  public addPlayerLimit(limit: any, amount: number): Observable<PlayerLimitation> {
    const limitEntity = limit;
    limit.amount = amount;
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/limits`;
    return this.http.post<PlayerLimitation>(url, limitEntity);
  }

  public addPlayerTimeLimit(limit: TimeLimitRequest): Observable<PlayerLimitation> {
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/daily-time`
    return this.http.post<PlayerLimitation>(url, limit);
  }

  public addSelfExclusion(limit: SelfExclusionRequest): Observable<PlayerLimitation> {
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/restrictions`
    return this.http.post<PlayerLimitation>(url, limit);
  }

  public updateActivityCheck(request: ActivityCheckRequest): Observable<PlayerLimitation> {
    const url = `${environment.ENDPOINTS.API.URL}/responsible-gambling/restrictions`
    return this.http.post<PlayerLimitation>(url, request);
  }

  public getCardDetails(userId: number): Observable<PlayerCardDetails> {
    const url = `${environment.ENDPOINTS.API.URL}/players/card/${userId}`
    return this.http.get<PlayerCardDetails>(url);
  }

  public getReferralCode(): Observable<ReferralCode> {
    const url = `${environment.ENDPOINTS.API.URL}/players/referral-code`;
    return this.http.get<ReferralCode>(url);
  }
}
