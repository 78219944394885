<section>
    <header>
        <button class="nav-btn back" (click)="goBack()" aria-label="Go back">
            <ng-container *ngIf="showBackButton">
                <i class="u_arrow-circle-left"></i>
                <a>Back</a>
            </ng-container>
        </button>
        <img class="logo" src="../../../../assets/images/LuckySweep-Logo.svg" alt="Casino logo">
        <button class="nav-btn close" (click)="close()" aria-label="Close button">
            <i class="u_times-circle"></i>
            <a>Close</a>
        </button>
    </header>
</section>