import {ComponentRef, Inject, Injectable, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ScriptService {
    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    /**
     * Append the JS tag to the Document Body.
     * @param renderer The Angular Renderer
     * @param src The path to the script
     * @returns the script element
     */
    public loadJsScript(renderer: Renderer2, src: string, identifier: string, callback: Function | null = null): void {
        sessionStorage.removeItem(`${identifier}_loaded`);

        const currentScript = this.document.getElementById(identifier) as any;

        if (currentScript) {
            renderer.removeChild(this.document.body, currentScript);
        }

        const script = renderer.createElement('script');

        script.id = identifier;
        script.type = 'text/javascript';
        script.src = src;

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    sessionStorage.setItem(`${identifier}_loaded`, 'true');
                    if (callback) callback();
                }
            };
        } else {
            script.onload = function () {
                sessionStorage.setItem(`${identifier}_loaded`, 'true');
                if (callback) callback();
            };
        }

        renderer.appendChild(this.document.body, script);

        const waitForScriptLoad = () => {
            setTimeout(() => {
                const wasLoaded = sessionStorage.getItem(`${identifier}_loaded`);

                if (wasLoaded !== 'true') {
                    this.loadJsScript(renderer, src, identifier, callback);
                }
            }, 1000);
        }

        waitForScriptLoad();
    }
}
