import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CoinPackageOffer, PaymentName} from "../../../../core";
import {combineLatest, Subject, Subscription} from "rxjs";
import {takeUntil, withLatestFrom} from "rxjs/operators";
import {PaymentsService, UserInfoService} from "../../../../core/store";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EVENT_TYPES} from "../../../constants";
import {TransactionStatus} from "../../../../core/enums/transaction";
import {EventBus} from "../../../../core/infrastructure";

declare var isignthis: any;

@Component({
    selector: 'app-flykk-payment',
    templateUrl: './flykk-payment.component.html',
    styleUrls: ['./flykk-payment.component.scss']
})
export class FlykkPaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private completeSubscription$: Subscription | null = null;

    public inProgress = false;
    public hasEmbedded = false;
    public hasIframe = false;
    public iframeUrl: SafeResourceUrl | null = null;

    @Input()
    public coin!: CoinPackageOffer;

    @Input()
    public showButton: boolean = false;

    @Input()
    public useIframe: boolean = false;

    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private userInfoService: UserInfoService,
        private sanitizer: DomSanitizer,
        private eventBus: EventBus,
        private paymentsService: PaymentsService,
    ) {
    }

    ngOnInit(): void {
        this.eventBus.OnChange<number>(EVENT_TYPES.PAYMENT_STARTED).pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.paymentsService.getProviders())
        ).subscribe(([providerId, providers]) => {
            const providerName = providers.find(p => p.id === providerId)?.name;

            if (providerName === PaymentName.FLYKK  || providerName === PaymentName.ISX ) {
                this.initPayment(providerName === PaymentName.ISX)
            }

            if (providerName === PaymentName.FLYKK) {
                this.startEmbeddedPayment();
            }

            if (providerName === PaymentName.ISX) {
                this.startIframePayment();
            }
        })
    }

    public initPayment(useIframe: boolean) {
        if (useIframe) {
            this.startIframePayment();
        } else {
            this.startEmbeddedPayment();
        }

        this.completeSubscription$ = this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.close();

            this.onComplete.emit({
                status: msg.status
            });
        })
    }

    public startEmbeddedPayment() {
        this.hasEmbedded = true;
        this.inProgress = true;

        this.paymentsService.initiateFlykkPayment(this.coin.id).then((data) => {
            //window.open(url, '_blank');

            const options = {
                transaction_id: data.transactionId,
                container_id: "checkout",
                language: 'en'
            };

            isignthis.setup(options).done((e: any) => {
                console.log("completed. e=", JSON.stringify(e));
            }).fail((e: any) => {
                console.log("error. e=" + JSON.stringify(e));
            }).continueLater((e: any) => {
                console.log("continueLater. e=" + JSON.stringify(e));
            }).route((e: any) => {
                console.log("route. e=" + JSON.stringify(e));
            }).resized((e: any) => {
                console.log("resized. e=" + JSON.stringify(e));
            }).completed((e: any) => {
                console.log("completed. e=" + JSON.stringify(e));

                this.userInfoService.reloadBalances();

                this.close();
                this.onComplete.emit({
                    status: TransactionStatus.Succeeded
                });
            }).publish();
        }).finally(() => {
            this.inProgress = false;
        })
    }

    public startIframePayment() {
        this.hasIframe = true;
        this.inProgress = true;

        this.paymentsService.initiateFlykkPayment(this.coin.id, true).then((data) => {
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
        }).finally(() => {
            this.inProgress = false;
            this.close();
        })
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public close() {
        this.inProgress = false;
        this.hasEmbedded = false;
        this.hasIframe = false;

        this.onClose.emit();
        this.completeSubscription$?.unsubscribe();
    }
}
