import { AbstractControl } from "@angular/forms";

export class ZipcodeValidators {
    static checkIfContainFiveDigits(control: AbstractControl): {[key: string]: any} | null {
        if (!control.value) return null;
        if (control.value.toString().length !== 5) {
            return { 'notContainFiveDigits': true };
        } else {
            return null;
        }
    }
}