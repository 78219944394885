<ng-container *ngIf="showButton">
    <button class="btn btn-block btn-extra-large btn-blue payment-button" (click)="initPayment()" [disabled]="inProgress">
        <img src="../../../../../assets/images/nuvei-logo.webp" alt="nuvei">
    </button>
</ng-container>
<div class="modal-container" [class.visible]="hasOverlay">
    <div class="modal-backdrop"></div>
    <div class="modal">
        <div class="modal__header">
            <h1>Checkout</h1>
            <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
        </div>
        <div class="modal__content">
            <div id="checkout">
                <div>The checkout is displayed here.</div>
            </div>
        </div>
    </div>
</div>