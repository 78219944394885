import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EnumHelper {
    public static GetObject(items: any, labels: Map<number, string>) {
        const list = Object.keys(items)
            .filter(key => !isNaN(Number(key)))
            .map(key => {
                const value = Number(key);
                return {
                    value: value,
                    text: labels.get(value)
                } as EnumObject;
            });

        return list;
    }
}

export interface EnumObject {
    value: number,
    text: string
}
