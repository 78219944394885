export enum TransactionStatus {
    None = 0,
    New = 1,
    Pending = 2,
    Succeeded = 3,
    Failed = 4,
    Canceled = 5
}

export enum TransactionType {
    Deposit = 1,
    Withdraw = 2,
    Purchase = 3,
    Redeem = 4,
    Refund = 5,
    Bet = 6,
    Win = 7,
    Rollback = 8
}

export enum TransactionCategory {
    All = 0,
    Deposit = 3,
    Withdraw = 4,
    Refund = 5,
    Bet = 6,
    Win = 7,
    Rollback = 8
}

export const TransactionCategoryLabel = new Map<number, string>(
    [
        [TransactionCategory.All, 'All'],
        [TransactionCategory.Deposit, 'Deposit'],
        [TransactionCategory.Withdraw, 'Withdraw'],
        [TransactionCategory.Refund, 'Refund'],
        [TransactionCategory.Bet, 'Bet'],
        [TransactionCategory.Win, 'Win'],
        [TransactionCategory.Rollback, 'Rollback']
    ])

export const TransactionStatusLabel = new Map<number, string>(
    [
        [TransactionStatus.New, 'New'],
        [TransactionStatus.Pending, 'Pending'],
        [TransactionStatus.Succeeded, 'Success'],
        [TransactionStatus.Failed, 'Failed'],
        [TransactionStatus.Canceled, 'Canceled']
    ])