import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-form-control-checkbox',
  templateUrl: './form-control-checkbox.component.html',
  styleUrls: ['./form-control-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckboxComponent),
      multi: true
    }
  ]
})
export class FormControlCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input()
  public label: string = '';
  public onChange: any = () => {};
  public onTouch: any = () => {};

  public checked: boolean = false;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  constructor() { }

  ngOnInit(): void {
  }

  public writeValue(checked: boolean) {
    this.checked = checked;
  }

  public onModelChange(e: boolean) {
    // Step 5a: bind the changes to the local value
    this.checked = e;

    // Step 5b: Handle what should happen on the outside, if something changes on the inside
    this.onChange(e);
  }

}
