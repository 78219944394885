import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {SearchQuery, SearchService} from "../../../core/store";
import {Breakpoint} from "../../../core";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @ViewChild('search', { static: true }) search!: ElementRef;
  public isSignInPage: boolean = false;
  public searchValue: string = "";
  public isMobileView: boolean = false;

  constructor(
      private readonly _router: Router,
      private readonly _query: SearchQuery,
      private readonly _service: SearchService
  ) {}

  public ngOnInit(): void {
    this.onResize();

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isSignInPage = event.urlAfterRedirects.includes('sign-in');
      }
    })

    this._query.criteria$.subscribe((criteria) => {
      this.searchValue = criteria;
    })

    this.isSignInPage = this._router.url.includes('sign-in');
  }

  public onKeyUp($event: any) {
    this._service.updateCriteria($event.target.value);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    const viewPortWidth = window.innerWidth;
    this.isMobileView = viewPortWidth < Breakpoint.phablet;
  }

  clear() {
    this._service.updateCriteria("");

    if(this.isMobileView) return;
  }

}
