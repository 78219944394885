<form [formGroup]="form" class="form-container">
    <!-- VIEW DETAILS PART -->
    <ng-container *ngIf="hasDetails">
        <div class="fields-view-group">
            <div class="fields-view-item" *ngLet="form.get('firstName') as formInput">
                <label>Name</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
            <div class="fields-view-item" *ngLet="form.get('lastName') as formInput">
                <label>Surname</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
        </div>
        <div class="fields-view-group">
            <div class="fields-view-item" *ngLet="form.get('birthday') as formInput">
                <label>Date of birth</label>
                <span *ngIf="formInput">{{formInput.value | date:'dd/MM/YYYY' }}</span>
            </div>
            <div class="fields-view-item" *ngLet="form.get('country') as formInput">
                <label>Country</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
        </div>
        <div class="fields-view-group">
            <div class="fields-view-item" *ngLet="form.get('address') as formInput">
                <label>Address</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
            <div class="fields-view-item" *ngLet="form.get('postcode') as formInput">
                <label>Zip Code</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
        </div>
        <div class="fields-view-group">
            <div class="fields-view-item" *ngLet="form.get('city') as formInput">
                <label>City</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
            <div class="fields-view-item" *ngLet="form.get('state') as formInput">
                <label>State</label>
                <span *ngIf="formInput">{{formInput.value}}</span>
            </div>
        </div>
    </ng-container>
    <!-- USERNAME -->
    <ng-container *ngLet="form.get('username') as formInput">
        <mat-form-field
                *ngIf="formInput"
                appearance="outline"
                class="material-block material-block__input"
                hideRequiredMarker="true"
        >
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <mat-icon class="icon-gray" matSuffix>
                {{formInput.invalid ? 'error' : 'check_circle'}}
            </mat-icon>
            <mat-error
                    *ngIf="formInput.invalid && formInput.touched && getErrorMessage(formInput)">{{getErrorMessage(formInput)}}</mat-error>
            <mat-error *ngIf="formInput.hasError('maxlength') || formInput.hasError('minlength')">
                <span>Length should be between 3 and 12 symbols</span>
            </mat-error>
        </mat-form-field>
    </ng-container>
    <!-- EDIT DETAILS PART -->
    <ng-container *ngIf="!hasDetails">
        <div class="form-container__fields">
            <!-- NAME -->
            <ng-container *ngLet="form.get('firstName') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                        hideRequiredMarker="true"
                >
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
            <!-- SURNAME -->
            <ng-container *ngLet="form.get('lastName') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                        hideRequiredMarker="true"
                >
                    <mat-label>Surname</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="form-container__fields">
            <!-- BIRTH -->
            <ng-container *ngLet="form.get('birthday') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__datepicker material-block__datepicker-gray"
                        hideRequiredMarker="true"
                >
                    <mat-label>Date of birth</mat-label>
                    <input formControlName="birthday" [max]="maxDate" [min]="minDate" matInput [matDatepicker]="birthdayPicker">
                    <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdayPicker></mat-datepicker>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
            <!-- COUNTRY -->
            <ng-container *ngLet="form.get('country') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__select material-block__select-gray"
                        hideRequiredMarker="true"
                >
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                        <mat-option *ngFor="let item of countries" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="form-container__fields">
            <!-- ADDRESS -->
            <ng-container *ngLet="form.get('address') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                        hideRequiredMarker="true"
                >
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="address">
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
            <!-- ZIP CODE -->
            <ng-container *ngLet="form.get('postcode') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                        hideRequiredMarker="true"
                >
                    <mat-label>Zip Code</mat-label>
                    <input matInput formControlName="postcode" [mask]="'00000'">
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="form-container__fields">
            <!-- CITY -->
            <ng-container *ngLet="form.get('city') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__input"
                        hideRequiredMarker="true"
                >
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city">
                    <mat-icon class="icon-gray" matSuffix>
                        {{formInput.invalid ? 'error' : 'check_circle'}}
                    </mat-icon>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
            <!-- STATE -->
            <ng-container *ngLet="form.get('state') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__select material-block__select-gray"
                        hideRequiredMarker="true"
                >
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of states" [value]="state.Title">
                            {{state.Title}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
    </ng-container>
    <!-- PHONE AND CODE -->
    <div class="form-container__inline">
        <!-- CODE -->
        <ng-container *ngLet="form.get('countryCode') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__select country-select material-block__select-gray"
                    hideRequiredMarker="true"
            >
                <mat-label>Code</mat-label>
                <mat-select formControlName="countryCode">
                    <mat-select-trigger>
                        <div class="country-value">
                            <img [src]="formInput.value?.icon" width="16"/>
                            <span> {{formInput.value?.value}}</span>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let code of countryCodes" [value]="code">
                        <div class="country-value">
                            <img [src]="code.icon" width="24"/>
                            <span> {{code.text}}</span>
                        </div>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
        <!-- PHONE -->
        <ng-container *ngLet="form.get('phone') as formInput">
            <mat-form-field
                    *ngIf="formInput"
                    appearance="outline"
                    class="material-block material-block__input"
                    hideRequiredMarker="true"
            >
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phone" [mask]="'0000000000'">
                <mat-icon class="icon-gray" matSuffix>
                    {{formInput.invalid ? 'error' : 'check_circle'}}
                </mat-icon>
                <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>
    <!-- TERMS -->
<!--    <ng-container *ngLet="form.get('age') as formInput">-->
<!--        <div class="mt-21 mb-32" *ngIf="showTerms && formInput">-->
<!--            <label class="form-checkbox"-->
<!--                   [class.error]="(formInput.dirty || formInput.touched) && formInput.value === false"-->
<!--            >-->
<!--                <input formControlName="age" type="checkbox">-->
<!--                <span class="terms-and-conditions">-->
<!--                I hereby confirm that I am at least 21 years old and that I agree to Sweeptastic's-->
<!--                <a [routerLink]="'/pages/terms-and-conditions'">terms and conditions</a> and-->
<!--                <a [routerLink]="'/pages/privacy-policy'">privacy policy</a>.-->
<!--                I confirm that I do not own an existing account on Sweeptastic, and that I am aware that Sweeptastic reserves the right to ban all duplicate-->
<!--                accounts registered by me or in my name and nullify any and all prizes in all duplicate accounts.-->
<!--            </span>-->
<!--            </label>-->
<!--        </div>-->
<!--    </ng-container>-->
</form>
