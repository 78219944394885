import {Component, OnInit} from '@angular/core';
import {CoinPackageService} from "../../../core/services/coin-package.service";
import {ModalService} from "../../services";

@Component({
  selector: 'app-free-coins-modal',
  templateUrl: './free-coins-modal.component.html',
  styleUrls: ['./free-coins-modal.component.scss']
})
export class FreeCoinsModalComponent implements OnInit {
  public isLC: boolean = false;
  constructor(
      private readonly coinPackageService: CoinPackageService,
      private readonly modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.modalService.closeAll();
  }

  openStore() {
    this.close();
    this.coinPackageService.openCoinStoreModal();
  }
}