import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    CoinPackageOffer,
    PaymentName,
    PaymentMethod,
    PaymentProvider,
    PaymentsProvider,
    PaymentType,
} from "../../../../../core";
import {EventBus} from "../../../../../core/infrastructure";
import {EVENT_TYPES} from "../../../../constants";
import {PaymentsService} from "../../../../../core/store";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Output()
    public onCancelPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onBackButtonPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onPaymentComplete: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public coin!: CoinPackageOffer;

    public paymentProviders: PaymentProvider[] = [];
    public paymentMethods: PaymentMethod[] = [];
    public paymentInProgress: boolean = false;

    constructor(
        private readonly eventBus: EventBus,
        private readonly paymentsService: PaymentsService,
        private readonly paymentsProvider: PaymentsProvider,
    ) {
    }

    ngOnInit(): void {
        this.paymentsProvider.getActivePaymentMethods().pipe(
            takeUntil(this.destroy$),
        ).subscribe((methods) => {
            this.paymentMethods = methods;

            if (this.coin.paymentMethods.length > 0) {
                this.paymentMethods = methods.filter(m => this.coin.paymentMethods.includes(m.id));
            }
        });

        this.paymentsService.getProviders().pipe(
            takeUntil(this.destroy$),
        ).subscribe((providers) => {
            this.paymentProviders = providers
        });
    }

    isGooglePay(method: PaymentMethod): boolean {
        const paymentProvider = this.paymentProviders.find(p => p.id === method.defaultProviderId);

        if (paymentProvider)
            return paymentProvider.type === PaymentType.GooglePay;

        return false;
    }

    close(): void {
        this.onCancelPressed.emit(false);
    }

    back(): void {
        this.onBackButtonPressed.emit(true);
    }

    onComplete(status: any) {
        this.paymentInProgress = false;
        this.onPaymentComplete.emit(status)
    }

    onClose() {
        this.paymentInProgress = false;
    }

    startPayment(paymentMethod: PaymentMethod) {
        this.eventBus.Publish(EVENT_TYPES.PAYMENT_STARTED, paymentMethod.defaultProviderId);

        this.paymentInProgress = true;
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
