import {Component} from '@angular/core';
import {Location, PlatformLocation} from "@angular/common";
import {ModalService} from "../../services";
import {NavigationService} from "../../../core";

@Component({
    selector: 'app-sign-in-modal',
    templateUrl: './sign-in-modal.component.html',
    styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent {
    public onClose: Function | null = null;

    constructor(
        private readonly platformLocation: PlatformLocation,
        private readonly location: Location,
        private readonly modalService: ModalService,
        private readonly navigationService: NavigationService,
    ) {
        localStorage.setItem('urlAfterSignIn', this.location.path())

        this.platformLocation.onPopState(() => {
            this.close();
        });
    }

    public close() {
        this.modalService.closeAll();

        if (this.onClose === null)
            return;

        this.onClose();
    }
}
