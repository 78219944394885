<section class="search" [ngClass]="{'search-sign-in': isSignInPage}">
    <ng-content select=".left-side"></ng-content>
    <div class="search-control">
        <input
            #search
            type="text"
            aria-label="Search games"
            placeholder="Search games"
            [value]="searchValue"
            (keyup)="onKeyUp($event)"
        />
        <button class="btn btn-icon" *ngIf="!searchValue">
            <i class="icon u_search" aria-label="Search"></i>
        </button>
        <button class="btn btn-icon" aria-label="Clear" *ngIf="searchValue" (click)="clear()">
            <i class="icon u_multiply" aria-describedby="Clear"></i>
        </button>
    </div>
    <ng-content select=".right-side"></ng-content>
</section>



