<div #searchContainer class="search-results">
    <div class="search-results__header">
        <div class="dummy"></div>
        <div class="text">Search</div>
        <div class="icon-container" (click)="close()">
            <i aria-describedby="Close" class="u_times-circle"></i>
        </div>
    </div>
    <div class="search-results__container">
        <div *ngIf="!showRecent && (!games || games.length === 0)" class="no-results">
            <i class="u_annoyed" aria-describedby="No result"></i>
            <span>
                No results found, please
                try search again.
            </span>
        </div>
        <div class="game-grid" *ngIf="!showRecent">
            <app-game-card *ngFor="let game of games" [game]="game" [showFavorite]="true" (onLaunch)="close()"></app-game-card>
        </div>
        <div class="game-grid bottom-content" *ngIf="showRecent">
            <app-game-card *ngFor="let game of recentGames" [game]="game" [showFavorite]="true" (onLaunch)="close()"></app-game-card>
        </div>
    </div>
    <div class="search-results__input">
        <app-search-input></app-search-input>
    </div>
</div>
