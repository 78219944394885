export enum Currency {
    AUD = 36,
    CAD = 124,
    USD = 840,
    EUR = 978,
    SWPC = 5001,
    LUKC = 5002,
}

export const CurrencyLabel = new Map<number, string>(
    [
        [Currency.AUD, 'AUD'],
        [Currency.CAD, 'CAD'],
        [Currency.USD, 'USD'],
        [Currency.EUR, 'EUR'],
        [Currency.SWPC, 'SC'],
        [Currency.LUKC, 'LC']
    ]
)
