<div class="redeems-container" *ngIf="redeemRequests.length > 0">
    <div class="redeems-header">
        <div class="redeems-header__title">Pending Prize Redemption Requests</div>
        <div class="redeems-header__subtitle">You can cancel a pending request and the Sweeps Coins will be returned to your balance instantly.</div>
    </div>
    <div class="cards-grid">
        <div class="card" *ngFor="let request of redeemRequests">
            <div class="card__label">
                <div class="title">PENDING REQUEST</div>
            </div>
            <div class="card__content">
                <div class="card__content__title">
                    {{request.amount | number : '1.2-2'}} SC
                </div>
            </div>
            <div class="card__actions">
                <button (click)="cancel(request)" class="btn btn-large btn-block">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

