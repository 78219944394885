<div class="modal">
    <div class="modal__header">
        <h1>Level {{levelNumber}}</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <div class="level-header" *ngIf="level">
            <div class="level-header__level accent" *ngIf="!level.Title">
                <span>Level {{level.Level}}</span>
            </div>
            <div class="level-header__title accent" *ngIf="level.Title">
                <span>{{level.Title}}</span>
            </div>
            <div class="level-header__points">
                <span>{{level.EntryPoints}}</span>
                <span>-</span>
                <span>{{level.ExitPoints}}</span>
                <span>Points</span>
            </div>
        </div>

        <app-level-details [levelNumber]="levelNumber"></app-level-details>
    </div>

    <div class="modal__actions">
        <button class="btn btn-large btn-outlined" (click)="close()">Close</button>
    </div>
</div>
