<div class="modal">
    <div class="modal__header">
        <h1>Activity check</h1>
        <i (click)="continue()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <i class="icon-verified-shield" aria-describedby="Annotation"></i>
        <span>
            <span class="message" *ngIf="limit">You have been logged in for {{limit.activityCheck}} minutes.</span>

            <span class="message">Time for a break?</span>
        </span>
    </div>

    <div class="modal__actions">
        <button class="btn btn-large btn-primary" (click)="continue()">Continue</button>
        <button class="btn btn-large btn-outlined" (click)="goToLogoutPage()">Log out</button>
    </div>
</div>
