<div class="games-container">
  <div class="games-container__header">
    <div class="hide-btn-container" (click)="closeBar()">
      <i class="icon close" aria-describedby="Close bar"></i>
    </div>
    <h3 class="header-title">My Games</h3>
  </div>
  <div class="games-container__body">
    <div *ngFor="let game of favoriteGames" class="game">
      <app-game-card [game]="game" [showDelete]="true" (onLaunch)="closeBar()"></app-game-card>
    </div>
  </div>
</div>
