export enum RestrictionType {
    None,
    Timeout,
    SelfExclusion,
}

export enum RestrictionPeriodType {
    None = 0,
    // timeout
    SeventyTwoHours = 1,
    SevenDays = 2,
    TwoWeeks = 3,
    FourWeeks = 4,
    SixWeeks = 5,
    // self-exclusion
    SixMonth = 6,
    OneYear = 7,
    ThreeYears = 8,
    FiveYears = 9,
    Custom = 10,
    TwentyFourHours = 11,
    ThirtyDays = 12,
}

export const RestrictionPeriodTypeLabel = new Map<number, string>(
    [[RestrictionPeriodType.None, 'None'],
        [RestrictionPeriodType.TwentyFourHours, 'TwentyFourHours'],
        [RestrictionPeriodType.SeventyTwoHours, 'SeventyTwoHours'],
        [RestrictionPeriodType.SevenDays, 'SevenDays'],
        [RestrictionPeriodType.TwoWeeks, 'TwoWeeks'],
        [RestrictionPeriodType.FourWeeks, 'FourWeeks'],
        [RestrictionPeriodType.ThirtyDays, 'ThirtyDays'],
        [RestrictionPeriodType.SixWeeks, 'SixWeeks'],
        [RestrictionPeriodType.SixMonth, 'SixMonth'],
        [RestrictionPeriodType.OneYear, 'OneYear'],
        [RestrictionPeriodType.ThreeYears, 'ThreeYears'],
        [RestrictionPeriodType.FiveYears, 'FiveYears'],
        [RestrictionPeriodType.Custom, 'Custom']
    ])
