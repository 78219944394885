import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CoinPackageOffer,
  NotificationService,
  PaymentName,
} from '../../../../core';
import { Subject } from 'rxjs';
import { PaymentsService } from '../../../../core/store';
import { EVENT_TYPES } from '../../../constants';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EventBus } from '../../../../core/infrastructure';
import { TransactionStatus } from '../../../../core/enums/transaction';

declare var PNM: any;

@Component({
  selector: 'app-paynearme-payment',
  templateUrl: './paynearme-payment.component.html',
  styleUrls: ['./paynearme-payment.component.scss'],
})
export class PaynearmePaymentComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  @HostListener('window:unload')
  cancelPayment() {
    this.handleCancel();
  }

  @Input()
  public coin!: CoinPackageOffer;

  @Input()
  public showButton: boolean = false;

  @Output()
  public onComplete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter<any>();

  public inProgress = false;
  private timerId: NodeJS.Timeout | null = null;

  constructor(
    private readonly paymentsService: PaymentsService,
    private readonly eventBus: EventBus,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.eventBus
      .OnChange<number>(EVENT_TYPES.PAYMENT_STARTED)
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.paymentsService.getProviders())
      )
      .subscribe(([providerId, providers]) => {
        const currentProvider = providers.find(p => p.id === providerId);

        if (currentProvider && currentProvider.name === PaymentName.PAYNEARME) {
          this.initPayment();
        }
      });
  }

  ngOnDestroy() {
    this.clearTimer();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  close() {
    this.inProgress = false;

    PNM.close();

    window.removeEventListener('message', PNM.receiveMessage, false);

    this.onClose.emit();
  }

  initPayment() {
    this.startTimer();
    this.inProgress = true;

    this.paymentsService
      .initiatePayNearMePayment({
        coinPackageId: this.coin.id,
      })
      .pipe(take(1))
      .subscribe({
        next: data => {
          if (!data?.smartToken) {
            this.onComplete.emit({
              status: TransactionStatus.Failed,
            });
            this.close();
            return;
          }

          const pnmConfig = {
            'order_token': data?.smartToken,
            'callback': this.handlePaymentCallback,
            'show_header': true,
            'auto_resize': true,
            'language': 'en',
            'actions': {
              'perform-payment': {
                'action': 'pay',
                'payment_field_fixed': 'true',
                'debit': true,
                'credit': true,
              },
            },
          };

          PNM.init(pnmConfig);
          PNM.launch('perform-payment');
        },
        error: response => {
          this.close();
          this.notificationService.showNotification({
            type: 'error',
            message: response.error.detail,
          });
        },
      });
  }

  handlePaymentCallback = (data: any) => {
    if (!data.status) return;

    switch (data.status) {
      case 'error': {
        this.onComplete.emit({
          status: TransactionStatus.Failed,
        });
        this.close();
        break;
      }

      case 'complete': {
        this.onComplete.emit({
          status: TransactionStatus.Succeeded,
        });
        this.close();
        break;
      }

      case 'info': {
        if (data.error === 'Modal closed by user') {
          this.handleCancel();
        }
        break;
      }

      case 'exit': {
        this.handleCancel();
        break;
      }

      default: {
        this.close();
        break;
      }
    }
  };

  handleCancel() {
    if (!this.inProgress) return;

    this.paymentsService
      .cancelPayNearMePayment({
        coinPackageId: this.coin.id,
      })
      .subscribe();

    this.clearTimer();

    this.close();
  }

  private startTimer(): void {
    const timeoutInMiliseconds = /*5 minutes*/ 300 * 1000;

    this.clearTimer();
    this.timerId = setTimeout(() => {
      this.notificationService.showNotification(
        {
          type: 'warning',
          message:
            'Your payment session has been expired, please start the new one.',
        },
        true
      );

      this.handleCancel();
    }, timeoutInMiliseconds);
  }

  private clearTimer(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }
}
