import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AuthService,
    NavigationBarService,
    UserInfoService
} from "../../../core/store";
import {ModalService} from "../../services";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-casino-header',
    templateUrl: './casino-header.component.html',
    styleUrls: ['./casino-header.component.scss']
})
export class CasinoHeaderComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public balance!: number;
    public currency!: string | undefined;
    public isAuthenticated: boolean = false;

    constructor(
        private readonly navigationBarService: NavigationBarService,
        private readonly authService: AuthService,
    ) {
    }

    public showModalMenu(): void {
        this.navigationBarService.openModal()
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
        ).subscribe((isAuthenticated) => {
            this.isAuthenticated = isAuthenticated;
        })
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }
}
