import {Injectable} from "@angular/core";
import {SearchStore} from "./search.store";

@Injectable({providedIn: 'root'})
export class SearchService {
  constructor(
    private readonly _store: SearchStore,
  ) {
  }

  public updateCriteria(criteria: string): void {
    this._store.updateCriteria(criteria);
  }
}
