export enum SignUpRoutes {
    email = 1,
    password = 2,
    terms = 3,
}

export enum LoginType {
    None,
    Facebook
}

export enum FacebookLoginType {
    Login = 'login',
    SignUp = 'signup'
}

export enum CoinAreaType {
    Signup = 'Signup',
    FBSignup = 'FBSignup',
    Login = 'Login'
}
