import { 
    Directive, 
    ElementRef, 
    HostListener, 
    Input, 
    OnDestroy 
} from '@angular/core';

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnDestroy {
    @Input() tooltip!: string;
    @Input() bgColor: 'black' | 'white' = 'black';
    @Input() title?: string;
    @Input() center: boolean = true;

    private myPopup!: HTMLElement;

    constructor(
        private _el: ElementRef,
    ) { }

    ngOnDestroy(): void {
        if (this.myPopup) { 
            this.myPopup.remove();
        }
    }

    @HostListener('mouseenter')
    onMouserEneter(): void {
        if (!this._el) return;
        let x = this._el.nativeElement?.getBoundingClientRect().left + this._el.nativeElement?.offsetWidth / 2; // Get the middle of the element
        let y = this._el.nativeElement?.getBoundingClientRect().top + this._el.nativeElement?.offsetHeight + 6; // Get the bottom of the element, plus a little extra
        this.createTooltipPopup(x, y);
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.myPopup.remove();
    }

    private createTooltipPopup(x: number, y: number): void {
        let popup = document.createElement('div');
        const center = this.center ? 'center': '';
        popup.innerHTML = this.title ? 
        `<h1>${this.title}</h1><span>${this.tooltip}<span/>` :
        `<span>${this.tooltip}</span>`;
        popup.setAttribute("class", `tooltip-container ${this.bgColor} ${center}`);
        popup.style.top = y.toString() + "px";
        popup.style.left = x.toString() + "px";
        document.body.appendChild(popup);
        this.myPopup = popup;
    }
}