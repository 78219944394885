<div class="modal">
    <div class="modal__header">
        <h1>Claim Coins</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <h3 class="claim-info">You've successfully claimed {{coinPackage.goldCoins}} coins!</h3>

        <div class="icon-green-coin" aria-describedby="Green coin"></div>

        <app-button (onButtonClicked)="play()" icon="u_arrow-circle-right" text="Play Now"></app-button>
    </div>
</div>