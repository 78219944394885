<section class="search" [class.backdrop]="isOpen">
    <div class="search-control">
        <input #search type="text" placeholder="Search games" [value]="searchValue">
        <button class="btn btn-icon" aria-label="Search icon" *ngIf="!searchValue">
            <i class="icon u_search" aria-describedby="search"></i>
        </button>
        <button class="btn btn-icon" aria-label="Clear icon" *ngIf="searchValue" (click)="clear()">
            <i class="icon u_multiply" aria-describedby="Clear"></i>
        </button>
    </div>
    <div class="search-dropdown" [class]="dropdownPosition" *ngIf="isOpen">
        <div class="games-container" *ngIf="games.length > 0">
            <div class="game-item" *ngFor="let game of games">
                <app-game-card [game]="game" [showFavorite]="false" (onLaunch)="clear()"></app-game-card>
            </div>
        </div>
        <div *ngIf="games.length === 0" class="no-results">
            <i class="u_annoyed" aria-describedby="No result"></i>
            <span>
                No results found, please
                try search again.
            </span>
        </div>
    </div>
</section>
<div class="dropdown-backdrop" *ngIf="isOpen" (click)="clear()"></div>


