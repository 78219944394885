import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    CoinPackageButton,
    CoinPackageOffer,
    CoinPackageProvider
} from "../../../../../core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'app-package-buttons',
    templateUrl: './package-buttons.component.html',
    styleUrls: ['./package-buttons.component.scss']
})
export class PackageButtonsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public isLoading: boolean = true;
    public coinPackages: CoinPackageOffer[] = [];

    constructor(
        private readonly coinPackageProvider: CoinPackageProvider
    ) {
    }

    ngOnInit(): void {
        this.coinPackageProvider.getCategories().pipe(
            takeUntil(this.destroy$),
        ).subscribe((categories) => {
            if (categories.length <= 0)
                return;

            const selectedCategory = categories[0];

            this.coinPackages = selectedCategory.orderPackageIdList
                .map((packageId, index) => {
                    return {
                        item: selectedCategory.packageList.find(pl => pl.id === packageId),
                        index: index
                    }
                })
                .filter(data => !!data.item)
                .sort((a, b) => a.index! - b.index!)
                .map(data => data.item as CoinPackageOffer);

            this.isLoading = false;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
