import {Inject, Injectable} from "@angular/core";
import {CmsSettingsStore} from "./cms-settings.store";
import {DOCUMENT} from "@angular/common";
import {SettingsProvider} from "../../providers";
import {Observable} from "rxjs";
import {map, tap, withLatestFrom} from "rxjs/operators";
import {SettingItem} from "../../interfaces/settings";
import {CmsSettingsQuery} from "./cms-settings.query";

@Injectable({providedIn: 'root'})
export class CmsSettingsService {
    constructor(
        @Inject(DOCUMENT) private dom: any,
        private readonly cmsSettingsStore: CmsSettingsStore,
        private readonly cmsSettingsQuery: CmsSettingsQuery,
        private readonly settingsProvider: SettingsProvider,
    ) {
    }

    public getSettings(): Observable<SettingItem | null> {
        return this.cmsSettingsQuery.settings$.pipe(
            withLatestFrom(
                this.cmsSettingsQuery.settingsLoaded$,
                this.cmsSettingsQuery.settingsIsLoading$
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadSettings();
                }
            }),
            map(([settings, loaded]) => {
                return loaded ? settings : null;
            })
        )
    }

    private loadSettings(): void {
        this.cmsSettingsStore.setLoading(true);

        this.settingsProvider.getSettings().subscribe((settings) => {
            this.cmsSettingsStore.updateSettings(settings);
        })
    }
}