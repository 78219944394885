<div class="bank-details-content" *ngIf="bankDetails">
    <div class="bank-details-content__description">
        Please check that the details you’ve provided are correct.
    </div>
    <div class="bank-details-content__container">
        <div class="details">
            <div class="details-info">
                <div class="label">
                    Account Type
                </div>
                <div class="value">
                    {{accountTypeText}}
                </div>
            </div>
            <div class="details-info">
                <div class="label">
                    ABA Routing No.
                </div>
                <div class="value">
                    {{bankDetails.abaRoutingNumber}}
                </div>
            </div>
        </div>

        <div class="details-info">
            <div class="label">
                Bank Name
            </div>
            <div class="value">
                {{bankDetails.bankName}}
            </div>
        </div>
        <div class="details-info">
            <div class="label">
                Bank Account No.
            </div>
            <div class="value">
                {{bankDetails.bankAccountNumber}}
            </div>
        </div>
        <div class="details-info">
            <div class="label">
                Account Holder Name
            </div>
            <div class="value">
                {{bankDetails.accountHolderName}}
            </div>
        </div>
        <div class="details-info">
            <div class="label">
                Bank statement
            </div>
            <div class="value">
                {{bankDetails.file.name}}
            </div>
        </div>
    </div>
    <div>
        <button class="btn btn-large btn-block mb-16 mt-40" [class.loading]="inProgress" [disabled]="inProgress" (click)="save()">
            Save
            <i class="u_file ml-19" aria-describedby="Save"></i>
        </button>
        <button class="btn btn-large btn-outlined btn-block" (click)="back()">
            Edit bank details
        </button>
    </div>
</div>
