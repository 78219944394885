<article class="games-block">
    <header class="header" *ngIf="category">
        <div class="header__left">
            <img class="image-icon" *ngIf="category.Icon?.url" [src]="category.Icon?.url" [alt]="category.Title">
            <div class="title">{{ category.Title }}</div>
        </div>

        <div class="header__right" *ngIf="!vertical">
            <button class="btn btn-secondary show" [routerLink]="'/games/' + category.Slug">
                Show all
            </button>
            <ng-container *ngIf="showAll">
                <button class="btn btn-icon arrow-left" aria-label="Previous" (click)="goPrev($event)" [disabled]="!canGoPrev">
                    <i class="u_arrow-left" aria-describedby="Arrow left"></i>
                </button>
                <button class="btn btn-icon arrow-right" aria-label="Next" (click)="goNext($event)" [disabled]="!canGoNext">
                    <i class="u_arrow-right" aria-describedby="Arrow right"></i>
                </button>
            </ng-container>
        </div>
    </header>

    <div class="container"
         [class.vertical]="vertical"
         [class.loading]="isLoading"
         (scroll)="onScroll($event)"
    >
        <div *ngFor="let game of games" class="game-item">
            <app-game-card [game]="game"></app-game-card>
        </div>
        <div class="game-item placeholder" *ngFor="let i of [].constructor(9)">
            <div class="game-image-placeholder skeleton-loading"></div>
            <div class="game-title-placeholder skeleton-loading"></div>
        </div>
        <div id="end-line"></div>
    </div>
</article>
