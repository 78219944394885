import {Injectable} from "@angular/core";
import {ITokenObject} from "../../interfaces";
import {AuthStore} from "./auth.store";
import {lastValueFrom, Observable} from "rxjs";
import {AuthQuery} from "./auth.query";
import {map, tap, withLatestFrom} from "rxjs/operators";
import {AuthProvider} from "../../providers";

const TOKEN_KEY = 'auth.token';

@Injectable({providedIn: 'root'})
export class AuthService {
    constructor(
        private readonly authStore: AuthStore,
        private readonly authQuery: AuthQuery,
        private readonly authProvider: AuthProvider,
    ) {
    }

    public tokenFromStorage(): ITokenObject | null {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token)
            return JSON.parse(token);

        return null;
    }

    public token(): Observable<ITokenObject | null> {
        return this.authQuery.token$.pipe(
            withLatestFrom(
                this.authQuery.tokenLoaded$,
                this.authQuery.tokenIsLoading$,
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    const token = this.tokenFromStorage();
                    if (token)
                        this.setContext(token)
                }
            }),
            map(([token, loaded]) => {
                return loaded ? token : null;
            })
        )
    }

    public accessToken(): Observable<string | null> {
        return this.token().pipe(
            map(token => token?.access_token ?? null)
        )
    }

    public isAuthenticated(): Observable<boolean> {
        return this.accessToken().pipe(
            map(token => !!token)
        )
    }

    public isExpired(token: ITokenObject): boolean {
        const currentDate = new Date();
        const oldTime = new Date(token.created_on);
        const difference = Math.abs(currentDate.getTime() - oldTime.getTime()) / 1000;

        return difference > token.expires_in;
    }

    public getActiveAccessToken(): Promise<string> {
        return new Promise((resolve) => {
            const token = this.tokenFromStorage();

            if (!token) {
                resolve("");
                return;
            }

            const isExpired = this.isExpired(token);

            if (!isExpired) {
                resolve(token.access_token);
                return;
            }

            lastValueFrom(this.authProvider.refresh(token.refresh_token)).then((newToken) => {
                resolve(newToken.access_token);

                this.setContext(newToken);
            })
        })
    }

    public setContext(token: ITokenObject): void {
        token.created_on = new Date();

        localStorage.setItem(TOKEN_KEY, JSON.stringify(token));

        this.authStore.setToken(token)
    }

    public dispose(): void {
        localStorage.removeItem(TOKEN_KEY);

        this.authStore.clear();
    }
}
