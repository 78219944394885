import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService, PopupItem, PopupsProvider, PopupTriggerType} from "../../../core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ModalService} from "../../services";

@Component({
    selector: 'app-popups-container',
    templateUrl: './popups-container.component.html',
    styleUrls: ['./popups-container.component.scss']
})
export class PopupsContainerComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();

    public triggerType: PopupTriggerType | null = null;
    public popups: PopupItem[] = [];
    public currentIndex = 0;

    constructor(
        private readonly popupsProvider: PopupsProvider,
        private readonly modalService: ModalService,
        private readonly navigationService: NavigationService,
    ) {
    }

    get currentPopup(): PopupItem {
        return this.popups[this.currentIndex];
    }

    ngOnInit(): void {
        if (this.triggerType !== null) {
            this.popupsProvider.getByTrigger(this.triggerType).pipe(
                takeUntil(this.destroy$),
            ).subscribe((popups) => {
                const showedPopups = JSON.parse(localStorage.getItem('ShowedPopups') ?? "[]") as number[]

                this.popups = popups.filter(p => !showedPopups.includes(p.id));

                if (this.popups.length === 0) {
                    this.close();
                }
            })
        }
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    previousPopup() {
        this.currentIndex--;
    }

    nextPopup() {
        this.currentIndex++;
    }

    close() {
        this.modalService.closeAll();
    }

    closeAction() {
        if (!this.currentPopup.IsPersistent) {
            const showedPopups = JSON.parse(localStorage.getItem('ShowedPopups') ?? "[]") as number[];

            showedPopups.push(this.currentPopup.id)

            localStorage.setItem('ShowedPopups', JSON.stringify(showedPopups))
        }

        this.popups = this.popups.filter(p => p.id !== this.currentPopup.id);

        if (this.currentIndex > this.popups.length - 1) {
            this.currentIndex = 0;
        }

        if (this.popups.length === 0) {
            this.close();
        }
    }

    clickAction(popup: PopupItem) {
        if (popup.ActionUrl)
            this.navigationService.navigateTo(popup.ActionUrl);

        this.closeAction();
    }
}
