import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IngeniousService {
  private readonly platformId: Object;

  public ingeniousHandler = new BehaviorSubject<string>('initialValue');
  public userId = '';

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId;
    this.ingeniousHandler.pipe(skip(1)).subscribe(() => {
        this.setClickPIEvent()
    })
  }

  public setClickPIEvent(): void {
    if (isPlatformServer(this.platformId) || !environment) return;

    let itsClickPI: any = {
      siteId: window.location.href,
      custId: this.userId,
      sessionId: '',
      productId: window.location.href,
      locationHref: window.location.href,
      locationRrf: window.document.referrer,
      gdpr: environment.INGENIOUS?.GDPR ?? '0',
      gdprConsent: environment.INGENIOUS?.GDPR_CONSENT ?? '',
      advId: environment.INGENIOUS?.ADV_ID ?? '94093',
      trcDomain: environment.INGENIOUS?.TRC_DOMAIN ?? '9h3n8p.sweeptastic.com',
    };

    itsClickPI.custId = !!this.userId ? this.userId.toString() : '';

    (window as any).itsClickPI = itsClickPI;

    var en = function (v: any) {
      if (v) {
        if (typeof encodeURIComponent == 'function') {
          return encodeURIComponent(v);
        }
        return escape(v);
      } else {
        return;
      }
    };
    var ts = function () {
      var d = new Date();
      return d.getTime();
    };

    var im = function (s: any) {
      if (document.images) {
        let ia;
        if (typeof ia != 'object') {
          ia = [];
        }

        var i = ia.length;
        ia[i] = new Image();
        ia[i].src = s;
        ia[i].onload = function () {};
        ia[i].id = 'ingenious-img';
      } else {
        document.write(
          '<img src="' +
            s +
            '" height="1" width="1" border="0" alt="" style="display:none;">'
        );
      }
    };

    var pr = 'https:';
    var cp = function (o: any) {
      var v = 'tst=' + ts();
      if (o.siteId) {
        v += '&sid=' + en(o.siteId);
      }
      if (o.locationRrf) {
        v += '&rrf=' + en(o.locationRrf);
      }
      if (o.locationHref) {
        v += '&hrf=' + en(o.locationHref);
      }
      v += '&ver=' + en('4.31');
      if (o.paramRef) {
        v += '&prf=' + en(o.paramRef);
      }
      if (o.userVal1) {
        v += '&uv1=' + en(o.userVal1);
      }
      if (o.userVal2) {
        v += '&uv2=' + en(o.userVal2);
      }
      if (o.custId) {
        v += '&csi=' + en(o.custId);
      }
      if (o.sessionId) {
        v += '&session=' + en(o.sessionId);
      }
      if (o.productId) {
        v += '&pid=' + en(o.productId);
      }
      v += '&rmd=0';
      var s: any = screen.width ? screen.width : '0';
      s += 'X';
      s += screen.height ? screen.height : '0';
      s += 'X';
      s += screen.colorDepth ? screen.colorDepth : '0';
      v += '&scr=' + s;
      v += '&nck=';
      v += navigator.cookieEnabled ? navigator.cookieEnabled : 'null';
      v += '&njv=';
      v += navigator.javaEnabled() ? navigator.javaEnabled() : 'null';
      if (o.gdpr) {
        v += '&gdpr=' + en(o.gdpr);
      }
      if (o.gdprConsent) {
        v += '&gdpr_consent=' + en(o.gdprConsent);
      }
      return v;
    };
    var itsStartCPI = function (o: any) {
      var s = pr + '//' + o.trcDomain + '/ts/' + o.advId + '/tsc?' + cp(o);
      im(s);
    };
    itsStartCPI((window as any).itsClickPI);

    var a = document.createElement('script');
    a.type = 'text/javascript';
    a.async = true;
    a.src =
      'https://' +
      (window as any).itsClickPI.trcDomain +
      '/scripts/ts/' +
      (window as any).itsClickPI.advId +
      'contC.js';
    var s: any = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(a, s);
  }
}