export const EVENT_TYPES = {
  PLAYER_EDITED: 'playerEdited',
  PLAYER_LOCKED: 'playerLocked',
  PLAYER_ADJUSTMENT : 'playerAdjustment',
  PLAYER_SIGN_IN : 'playerSignIn',
  BALANCE_UPDATED: 'balanceUpdated',
  PURCHASE_COMPLETE: 'purchaseComplete',
  FREE_COINS_RECEIVED: 'freeCoinsReceived',
  PAYMENT_STARTED: 'paymentStarted',
  LOYALTY_LEVEL: 'loyaltyLevel',
  BANK_DETAILS: 'bankDetails',
  LANDING_EXIT: 'LandingExit',
  REDEEM_COMPLETE: 'redeemComplete',
  GAME_SPIN: 'gameSpin',
}

