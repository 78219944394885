import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  ActivatePassword,
  GenerateCodeRequest,
  SignUpModel,
  UserBalanceDetails,
  UserInfo,
  VerifyCodeRequest,
  FacebookUser,
  SignInModel
} from 'src/app/core/interfaces';
import {FacebookLoginType} from "../enums";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AuthProvider {
  public loggedOut = new BehaviorSubject<boolean>(false);

  constructor(
      private readonly http: HttpClient
  ) {
  }

  public getCurrentUser(): Observable<UserInfo> {
    const url = `${environment.ENDPOINTS.API.URL}/account/me`;

    return this.http.get<any>(url).pipe(
      map(res => {
        return {
          ...res.player,
          hasPassword: res.hasPassword,
          accessType: res.accessType
        }
      })
    );
  }

  public exchange(
      model: SignInModel | FacebookUser,
      isFacebook: boolean = false,
      isFacebookSignUp: boolean = false
  ): Observable<any> {
    switch (true) {
      case (isFacebook): {
        return this.getToken({
          username: model.email,
          password: isFacebookSignUp ? FacebookLoginType.SignUp : FacebookLoginType.Login,
          refresh_token: (model as FacebookUser).authToken,
          mac_address: (model as FacebookUser).id,
          channel: 'Facebook',
          grant_type: 'password',
          scope: 'openid offline_access api'
        })
      }
      default: {
        return this.getToken({
          username: (model as SignInModel).email,
          password: (model as SignInModel).password,
          grant_type: 'password',
          scope: 'openid offline_access api',
        });
      }
    }
  }

  public resetPassword(oldPassword: string, newPassword: string): Observable<any> {
    // const url = `https://dev.admin-9yards.com/api/account/password`;
    const url = `${environment.ENDPOINTS.API.URL}/account/password`;
    return this.http.post<any>(url, {
      password: oldPassword,
      newPassword
    })
  }

  public logout(): Observable<any> {
    // const url = `https://dev.admin-9yards.com/api/auth/logout`;
    const url = `${environment.ENDPOINTS.API.URL}/auth/logout`;

    return this.http.post<void>(url, null).pipe(
      tap(() => this.loggedOut.next(true))
    );
  }

  public refresh(refresh_token: string): Observable<any> {
    return this.getToken({
      refresh_token,
      grant_type: 'refresh_token',
    });
  }

  public register(model: SignUpModel): Observable<any> {
    // const url = `https://dev.admin-9yards.com/api/auth/register`;
    const url = `${environment.ENDPOINTS.API.URL}/auth/register`;

    return this.http.post<SignUpModel>(
      url,
      model
    )
  }

  public generateCode(data: GenerateCodeRequest): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/account/code/generate`;

    return this.http.post(url, data)
  }

  public verifyCode(data: VerifyCodeRequest): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/account/code/verify`;

    return this.http.post(url, data)
  }

  public activatePassword(data: ActivatePassword): Observable<any> {
    const url = `${environment.ENDPOINTS.API.URL}/account/activate`;

    return this.http.post(url, data)
  }

  public getBalance(id: number): Observable<UserBalanceDetails[]> {

    const url = `${environment.ENDPOINTS.API.URL}/wallets/player/${id}/balance`;

    return this.http.get<UserBalanceDetails[]>(url);
  }

  private getToken(params: any): Observable<any> {
    // const url = `https://dev.admin-9yards.com/api/auth/token`;
    const url = `${environment.ENDPOINTS.API.URL}/auth/token`;

    const clientParams: any = {
      client_id: environment.CLIENT_ID,
      client_secret: environment.CLIENT_SECRET,
    };

    return this.http.post<any>(
      url,
      new HttpParams({fromObject: {...clientParams, ...params}}),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      }
    );
  }
}
