import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {LeaderboardResponse} from "../interfaces";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class ReportsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getLeaders(take: number, skip: number): Observable<LeaderboardResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/reports/leaders?skip=${skip}&take=${take}`;

        return this.http.get<LeaderboardResponse>(url);
    }
}
