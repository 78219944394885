import {IConfig} from "../app/core";

export const environment: IConfig = {
    ENVIRONMENT: "production",
    CURRENT_URL: "https://sweeptastic.com",
    CANONICAL_DOMAIN: "https://sweeptastic.com",
    CURRENT_TITLE: "Sweeptastic",
    INGENIOUS: {
        TRC_DOMAIN: "9h3n8p.sweeptastic.com",
        ADV_ID: "94093",
        GDPR: "0",
        GDPR_CONSENT: "0"
    },
    ENDPOINTS: {
        GAMES: {
            URL: "https://cdn.softswiss.net",
            CASINO_ID: "sweeptastic",
            AUTH_TOKEN: "w1d6w2iku60w3kja",
            GCP_URL: "https://casino.int.a8r.games/",
            SCRIPT_URL: "https://casino.int.a8r.games/public/sg.js"
        },
        API: {
            URL: "/api",
            SSR_URL: "https://sweeptastic.com/api",
        },
        CMS: {
            URL: "/cms",
            SSR_URL: "https://cms.sweeptastic.com/api",
            CMS_TOKEN: "9a14f9071822bdd5ce6427ba4b49fb95aa8e636be850ac8bbf038a89ca6bad06867eb3ceb3d00944511352bb9b448ce8bfae744fdf46f8ddbad285523b7f2da45594e2e43d632ef416934f368abdce0868b90a6f9cf8b9bb27f7bcf77a0d3c27cf4010099276b8746da26c0fe3fd6a0ac5fcc4910a3ccc9bf688f6959bd11e60"
        }
    },
    CLIENT_ID: "CfResourceOwnerClientIdMobile",
    CLIENT_SECRET: "cf_resource_owner_flow_secret_222_mobile",
    SENTRY_DSN: 'https://5b881cf015ab8d8927beb94d34cc28d2@o4508024233656320.ingest.us.sentry.io/4508097947762688',
    FACEBOOK_APP_ID: "2180471435674166",
    FEATURES_SETTINGS: {
        ACTIVITY: "true",
        AUDIT: "true",
        BONUS: "true",
        GEOLOCATION: "true",
        KYC: "true",
        NOTIFICATION: "true",
        REPORTING: "true",
        RESPONSIBLE_GAMBLING: "true",
        WALLET: "true"
    },
    FLYKK_SETTINGS: {
        SCRIPT_URL: "https://verify.flykk.it/js/isx-embed.js?profiles=embedded"
    },
    CHECKOUT_SETTINGS: {
        PUBLIC_KEY: "pk_phxussg7ybxhltwdpbyqsqpype6"
    },
    PAYPAL_SETTINGS: {
        CLIENT_ID: "Aaizm-Yh6krko1xAaJ6YgxuPl6N9P0NVw0D8EwUQfAIFpXoXaMF1GNWhUGvjd9pVqGKVd2DewsTO7mLn"
    },
    SPREEDLY_SETTINGS: {
        SCRIPT_URL: "https://core.spreedly.com/iframe/iframe-v1.min.js",
        TOKEN: "ZMaXAmUlTUKdcLgUvBv1FB3zGUW"
    },
    GOOGLE_KEY: "GTM-NCD4J8W",
    GOOGLE_PAY_SETTINGS: {
        MERCHANT_ID: "12345678901234567890",
        MERCHANT_NAME: "Demo Merchant"
    },
    RECAPTCHA: {
        SITE_KEY: "6LfgMVwpAAAAANiU9wcVmu4LyFa7t2V8T34hcU0p"
    },
    PAYNEARME_SETTINGS: {
        SCRIPT_URL: "https://www.paynearme.com/api/cf/S4577436915/v1/paynearme.js"
    },
};