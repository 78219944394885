import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {AccountDetails, PlayerLimitation} from "../../interfaces";

const createInitialState = (): RgStateEntry => {
    return {
        limitationLoaded: false,
        limitationIsLoading: false,
        limitation: null,
        dailyLimit: undefined,
        dailyLimitValue: undefined,
        spendTime: undefined,
        timers: {} as timersRG
    };
}

export interface RgStateEntry {
    limitationLoaded: boolean,
    limitationIsLoading: boolean,
    limitation: PlayerLimitation | null,
    timers: timersRG,
    dailyLimit: string | undefined,
    dailyLimitValue: string | undefined,
    spendTime: string | undefined,
}

export interface timersRG {
    dailyTimer: boolean,
    activityTimer: boolean,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'rg', resettable: true})
export class ResponsibleGamblingStore extends Store <RgStateEntry> {
    constructor() {
        super(createInitialState());
    }

    public setLimitation(limitation: PlayerLimitation): void {
        this.update({
            limitation: limitation,
            limitationLoaded: true,
            limitationIsLoading: false,
        })
    }

    public setLimitationLoading(loading: boolean): void {
        this.update({
            limitationIsLoading: loading
        });
    }

    public setLimitationLoaded(loading: boolean): void {
        this.update({
            limitationLoaded: loading
        });
    }

    public setTimer(value: timersRG): void {
        this.update({
            timers: value
        })
    }

    public setDailyLimit(limit: string): void {
        this.update({
            dailyLimit: limit
        });
    }

    public setDailyLimitValue(limit: string): void {
        this.update({
            dailyLimitValue: limit
        });
    }

    public setSpendTime(limit: string): void {
        this.update({
            spendTime: limit
        });
    }

    public clear(): void {
        this.update(createInitialState());
    }
}
