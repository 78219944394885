import {BonusType} from "./bonus";
import {LimitationMetaData} from "../interfaces";

export enum LimitType {
    none,
    deposit,
    withdraw,
    bet,
    spend,
    time,
}

export enum LimitPeriodType {
    none,
    daily,
    weekly,
    monthly
}

export const LimitPeriodTypeLabel = new Map<number, string>([
    [LimitPeriodType.daily, 'Daily limit'],
    [LimitPeriodType.weekly, 'Weekly limit'],
    [LimitPeriodType.monthly, 'Monthly limit']
])

export enum LimitationMetaType {
    PurchaseLimit,
    ActivityCheck,
    PlayLimit,
    Timeout,
    TimeLimit,
    SelfExclusion,
}

export const LimitationMetaTypeLabel = new Map<number, string>([
    [LimitationMetaType.PurchaseLimit, 'Purchase limit'],
    [LimitationMetaType.ActivityCheck, 'Activity check'],
    [LimitationMetaType.PlayLimit, 'Play limit'],
    [LimitationMetaType.Timeout, 'Timeout'],
    [LimitationMetaType.TimeLimit, 'Time limit'],
    [LimitationMetaType.SelfExclusion, 'Self-exclusion']
])


// export enum TimeLimits {
//     'No limit' = 0,
//     '1 hour' = 1,
//     '2 hours' = 2,
//     '3 hours' = 3,
//     '4 hours' = 4,
//     '5 hours' = 5,
//     '6 hours' = 6,
//     '7 hours' = 7,
//     '8 hours' = 8,
//     '9 hours' = 9,
//     '10 hours' = 10,
//     '11 hours' = 11,
//     '12 hours' = 12,
//     '13 hours' = 13,
//     '14 hours' = 14,
//     '15 hours' = 15,
//     '16 hours' = 16,
//     '17 hours' = 17,
//     '18 hours' = 18,
//     '19 hours' = 19,
//     '20 hours' = 20,
//     '21 hours' = 21,
//     '22 hours' = 22,
//     '23 hours' = 23,
//     '24 hours' = 24,
// }
