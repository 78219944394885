import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AccountDetails,
  AuthFacade,
  NavigationLink,
  NavigationService,
} from 'src/app/core';
import { Router } from '@angular/router';
import {
  AccountService,
  AuthService,
  NavigationBarQuery,
  NavigationBarService,
  NavigationLinksService,
  UserInfoService,
} from 'src/app/core/store';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EventBus } from 'src/app/core/infrastructure';
import { Subject } from 'rxjs';
import { CoinPackageService } from '../../../core/services/coin-package.service';
import { EVENT_TYPES } from '../../constants';
import { ModalService } from '../../services';
import { DailySpinModalComponent } from '../../modals';
import { PlayerDetailsModalComponent } from '../../modals/player-details-modal/player-details-modal.component';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  public isMinimized: boolean = false;
  public navLinks: NavigationLink[] = [];
  public selectedRoute!: string;
  public isAuthenticated: boolean = false;
  public userDetails: AccountDetails | null = null;

  private destroy$ = new Subject<boolean>();

  public isModal: boolean = false;

  constructor(
    public readonly router: Router,
    private readonly authFacade: AuthFacade,
    private readonly navigationBarService: NavigationBarService,
    private readonly navigationBarQuery: NavigationBarQuery,
    private readonly navigationService: NavigationService,
    private readonly eventBus: EventBus,
    private readonly navigationLinksService: NavigationLinksService,
    private readonly _coinPackageService: CoinPackageService,
    private readonly authService: AuthService,
    private readonly modalService: ModalService,
    private readonly accountService: AccountService,
    private readonly userInfoService: UserInfoService
  ) {}

  ngOnInit(): void {
    this.authService
      .isAuthenticated()
      .pipe(
        takeUntil(this.destroy$),
        tap(isAuthenticated => {
          this.isAuthenticated = isAuthenticated;
        }),
        switchMap(isAuthenticated => {
          return isAuthenticated
            ? this.navigationLinksService.getLinks('sidebar-authenticated')
            : this.navigationLinksService.getLinks('sidebar-anonymous');
        })
      )
      .subscribe(links => {
        this.navLinks = links;

        this.selectedRoute = this.getLinkByLocation();
        this.isLoaded = true;
      });

    this.accountService
      .getDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        this.userDetails = details;
      });

    this.navigationBarQuery.isMinimized$
      .pipe(
        takeUntil(this.destroy$),
        tap(isMinimized => {
          this.isMinimized = isMinimized;
        })
      )
      .subscribe();

    this.eventBus
      .OnChange(EVENT_TYPES.PLAYER_LOCKED)
      .subscribe(shouldLogout => {
        if (shouldLogout) this.logout();
      });
  }

  logout(): void {
    this.authFacade.logout();
    this.navigationService.navigateAndRefreshTo('');
    if (this.isModal) {
      this.closeMenu();
    }
  }

  help(): void {
    this.navigationService.navigateAndRefreshTo('pages/help');
    if (this.isModal) {
      this.closeMenu();
    }
  }

  toggleSidebar(): void {
    this.isMinimized = !this.isMinimized;
    this.navigationBarService.toggleMinimize(this.isMinimized);
  }

  async navigateTo(route: string): Promise<void> {
    this.modalService.closeAll();

    switch (route) {
      case 'account': {
        this.navigationService.navigateTo(`/${route}`);
        break;
      }
      case 'coin-store': {
        this._coinPackageService.openCoinStoreModal();
        break;
      }
      case 'redeem-coins': {
        this._coinPackageService.openRedeemModal();
        break;
      }
      case 'daily-spin': {
        this.modalService.open(DailySpinModalComponent);
        break;
      }
      default: {
        this.navigationService.navigateTo(route);
        break;
      }
    }
  }

  async navigateWithPreservedQueryParams(url: string) {
    this.navigationService.navigateToWithPreservedQueryParams(url);

    this.closeMenu();
  }

  getLinkByLocation(): string {
    const firstChar = window.location.pathname.indexOf('/');
    const lastChar = window.location.pathname.lastIndexOf('/');

    const link =
      firstChar !== lastChar
        ? window.location.pathname.replace('/', '').split('/', -1)[0]
        : window.location.pathname.replace('/', '');

    return link;
  }

  closeMenu(): void {
    this.modalService.closeAll();
  }

  ngOnDestroy(): void {
    this.destroy$?.next(true);
    this.destroy$?.complete();
  }

  openDetails() {
    this.userInfoService
      .getUser()
      .pipe(
        filter(user => user !== null),
        take(1)
      )
      .subscribe(user => {
        const dialogRef = this.modalService.open(PlayerDetailsModalComponent);

        dialogRef.componentInstance.playerId = user?.id;
      });
  }
}