import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    CoinPackageOffer,
    NotificationService, PaymentName, PaymentType,
} from "../../../../core";
import {takeUntil, withLatestFrom} from "rxjs/operators";
import {combineLatest, Subject, Subscription} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EVENT_TYPES} from "../../../constants";
import {EventBus} from "../../../../core/infrastructure";
import {PaymentsService} from "../../../../core/store";

declare var checkout: any;

@Component({
    selector: 'app-nuvei-ibt-payment',
    templateUrl: './nuvei-ibt-payment.component.html',
    styleUrls: ['./nuvei-ibt-payment.component.scss']
})
export class NuveiIbtPaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private completeSubscription$: Subscription | null = null;

    public inProgress = false;
    public hasIframe = false;
    public iframeUrl: SafeResourceUrl | null = null;

    @Input()
    public coin!: CoinPackageOffer;

    @Input()
    public showButton: boolean = false;

    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private notificationService: NotificationService,
        private eventBus: EventBus,
        private paymentsService: PaymentsService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.eventBus.OnChange<number>(EVENT_TYPES.PAYMENT_STARTED).pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.paymentsService.getProviders())
        ).subscribe(([providerId, providers]) => {
            const currentProvider = providers.find(p => p.id === providerId);

            if (currentProvider && currentProvider.name === PaymentName.NUVEI && currentProvider.type === PaymentType.IBT) {
                this.initPayment();
            }
        })
    }

    public initPayment() {
        this.inProgress = true;

        this.paymentsService.initiateNuveiIbtPayment(this.coin.id).subscribe({
            next: (data) => {
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
                this.hasIframe = true;
                this.inProgress = false;
            },
            error: (response) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: response?.error?.detail ?? response
                });

                this.inProgress = false;

                this.close();
            }
        });

        this.completeSubscription$ = this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.close();

            this.onComplete.emit({
                status: msg.status
            });
        })
    }

    public close() {
        this.inProgress = false;
        this.hasIframe = false;

        this.onClose.emit();
        this.completeSubscription$?.unsubscribe();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
