<div class="actions" *ngIf="showButton">
    <button
            class="btn btn-extra-large {{useIframe ? 'btn-white payment-icons-button' : 'btn-blue payment-button'}}"
            (click)="initPayment(useIframe)" [disabled]="inProgress"
    >
        <ng-container *ngIf="useIframe">
            <img src="assets/images/cards/mastercard-logo.svg" alt="Mastercard">
            <img src="assets/images/cards/discover-card-logo.png" alt="Discover_Card">
            <img src="assets/images/cards/diners-club-logo.png" alt="Diners_Club">
            <img src="assets/images/cards/maestro-logo.svg" alt="Maestro">
        </ng-container>
        <ng-container *ngIf="!useIframe">
            <img src="assets/images/flykk.png" alt="flykk">
        </ng-container>
    </button>
</div>
<div class="modal-container" *ngIf="hasEmbedded">
    <div class="modal-backdrop"></div>
    <div class="modal">
        <div class="modal__header">
            <h1>Checkout</h1>
            <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
        </div>
        <div class="modal__content">
            <div id="checkout">
                <div *ngIf="inProgress">The checkout is displayed he`re.</div>
            </div>
        </div>
    </div>
</div>
<div class="modal-container" *ngIf="hasIframe">
    <div class="modal-backdrop"></div>
    <div class="modal">
        <div class="modal__header">
            <h1>Checkout</h1>
            <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
        </div>
        <div class="modal__content" *ngIf="iframeUrl">
            <iframe [src]="iframeUrl"></iframe>
        </div>
    </div>
</div>