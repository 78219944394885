import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {BankDetails} from "../../interfaces";

export interface BankDetailsStateEntry {
    bankDetails: BankDetails | null,
}

const createInitialState = (): BankDetailsStateEntry => {
    return {
        bankDetails: null,
    };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'bank-details', resettable: true})
export class BankDetailsStore extends Store <BankDetailsStateEntry> {
    constructor() {
        super(createInitialState());
    }

    public setBankDetails(bankDetails: BankDetails): void {
        this.update({
            bankDetails: bankDetails
        })
    }

    public clear(): void {
        this.update(createInitialState());
    }
}
