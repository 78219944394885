import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {GamesProvider} from "../../../core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {GamesService} from "../../../core/store";
import {LobbyGame} from "../../../core/interfaces/lobby";

@Component({
    selector: 'app-side-games',
    templateUrl: './side-games.component.html',
    styleUrls: ['./side-games.component.scss']
})
export class SideGamesComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    public favoriteGames: LobbyGame[] = [];

    constructor(
        private readonly gamesService: GamesService,
        private readonly gamesProvider: GamesProvider,
    ) {
    }

    ngOnInit(): void {
        this.gamesService.getMyGames().pipe(
            takeUntil(this.destroy$),
        ).subscribe(async (myGames) => {
            const favoriteGameIdentifiers = myGames.favoriteGames.map(item => item.gameId);
            this.favoriteGames = await this.gamesProvider.getGamesByIdentifiers(favoriteGameIdentifiers).toPromise() as LobbyGame[];
        });
    }

    public closeBar(): void {
        this.onClose.emit(true);
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
