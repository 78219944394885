<ng-container *ngIf="request">
    <google-pay-button
            [environment]="environment"
            buttonType="pay"
            buttonColor="white"
            buttonSizeMode="fill"
            class="btn-block"
            [paymentRequest]="request"
            (cancel)="close()"
            (click)="onStart()"
            [loadPaymentDataCallback]="onLoadPaymentData"
            (error)="onError($event)"
    ></google-pay-button>
</ng-container>
