import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {enableProdMode} from "@angular/core";
import {environment} from "./environments/environment";
import * as Sentry from '@sentry/angular';

console.log('ENVIRONMENT', environment.ENVIRONMENT)

Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.ENVIRONMENT,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: environment.ENVIRONMENT === 'production' ? 0.1 : 1, //  Capture 100% of the transactions in development
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        /https:\/\/(casino-(dev|stg)\.|)sweeptastic\.com/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0
});

if (environment.ENVIRONMENT === "production") {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
});