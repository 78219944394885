import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CheckPlatformService {

    constructor(
        @Inject(PLATFORM_ID) private readonly _platform: Object
    ) { }

    public checkIfIsPlatformBrowser(): boolean {
        return isPlatformBrowser(this._platform);
    }
    
}