import {HttpClient, HttpParams, HttpParamsOptions} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {TransactionHistoryResponse} from "../interfaces";
import {environment} from "../../../environments/environment";
import {TableDataResponse} from "../../shared/interfaces";

@Injectable({providedIn: 'root'})
export class TransactionProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getTransactionsHistory(params?: HttpParamsOptions): Observable<TableDataResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/transactions/history`;

        const httpParams = new HttpParams(params)

        return this.http.get<TableDataResponse>(url, {
            params: params ? httpParams : {}
        });
    }

    public getTransactionsPlayHistory(params?: HttpParamsOptions): Observable<TableDataResponse> {
        const url = `${environment.ENDPOINTS.API.URL}/transactions/history-play`;

        const httpParams = new HttpParams(params)

        return this.http.get<TableDataResponse>(url, {
            params: params ? httpParams : {}
        });
    }

    public getTransactionsSources(): Observable<any> {
        const url = `${environment.ENDPOINTS.API.URL}/transactions/sources`;

        return this.http.get<any>(url);
    }
}
